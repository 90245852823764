import React, { useEffect } from 'react';
import { Grid, Button, Typography, MobileStepper, Box, Divider, ButtonBase } from '@material-ui/core';
import styled from 'styled-components';

import CustomDialog from './customDialog';

import { OrderInstruction } from 'utils/interfaces';
import NoImage from 'assets/images/pngs/noImage.png';
import {
  useAcknowledgeItemInstructionsBatch,
  useAcknowledgeOrderInstruction,
  useVerifyItemInstructionsBatch,
  useVerifyOrderInstruction,
  useViewInstructionsBatch,
  useViewOrderInstruction,
} from 'services/mutations/useInstructions';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useIsRQLoading } from 'hooks';
import { startCase } from 'lodash';
import { ColorMapper } from 'components/colorMapper';
import { StyledButton } from 'modules/stations/components/photoCatcher';
interface OrderDetails {
  type: 'order';
  orderId: string;
  bagCode: string;
}

interface ItemDetails {
  type: 'item';
  orderId: string;
  itemCode: string;
}

interface PackageDetails {
  type: 'package';
  code: string;
}

interface DropoffBagDetails {
  type: 'dropoffBag';
  code: string;
}

export type EntityDetails = OrderDetails | ItemDetails | PackageDetails | DropoffBagDetails;

interface Props {
  onClose: () => any;
  open: boolean;
  orderInstructions: OrderInstruction[];
  stage: 'acknowledge' | 'verify';
  entity: EntityDetails;
  isBatched?: boolean;
  withDetails?: boolean;
}

const InstructionsDialog: React.FC<Props> = ({ onClose, open, orderInstructions, entity, stage, withDetails }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedImage, setSelectedImage] = React.useState(0);
  const [selectedReason, setSelectedReason] = React.useState('');
  const [settingReason, setSettingReason] = React.useState(false);

  const { mutateAsync: acknowledgeOrderInstruction } = useAcknowledgeOrderInstruction();
  const { mutateAsync: acknowledgeItemInstructionsBatch } = useAcknowledgeItemInstructionsBatch();
  const { mutateAsync: verifyOrderInstruction } = useVerifyOrderInstruction();
  const { mutateAsync: verifyItemInstructionsBatch } = useVerifyItemInstructionsBatch();
  const { mutateAsync: viewOrderInstruction } = useViewOrderInstruction();
  const { mutateAsync: viewItemInstructionsBatch } = useViewInstructionsBatch();

  const heroImgRef = React.createRef<HTMLImageElement>();
  const { isMutating } = useIsRQLoading();

  const handleViewInstruction = async () => {
    const activeOrderInstruction = orderInstructions[activeStep];
    if (entity.type === 'order') {
      await viewOrderInstruction({ orderId: entity.orderId, bagCode: entity.bagCode, instructionId: activeOrderInstruction?.id });
    } else if (entity.type === 'item' || !activeOrderInstruction?.ids?.length) {
      await viewItemInstructionsBatch({
        instructionIds: [activeOrderInstruction?.id],
      });
    } else if (entity.type === 'package' || entity.type === 'dropoffBag') {
      await viewItemInstructionsBatch({
        instructionIds: activeOrderInstruction?.ids ?? [],
      });
    }
  };

  useEffect(() => {
    if (open) {
      handleViewInstruction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, activeStep, orderInstructions]);
  useEffect(() => {
    setActiveStep(0);
    setSelectedImage(0);
    setSelectedReason('');
    setSettingReason(false);
  }, [open]);

  const handleAcknowledge = async () => {
    const activeOrderInstruction = orderInstructions[activeStep];
    if (entity.type === 'order') {
      await acknowledgeOrderInstruction(
        { orderId: entity.orderId, bagCode: entity.bagCode, instructionId: activeOrderInstruction?.id },
        {
          onSuccess: handleGoNext,
        }
      );
    } else if (entity.type === 'item' || !activeOrderInstruction?.ids?.length) {
      await acknowledgeItemInstructionsBatch(
        {
          instructionIds: [activeOrderInstruction?.id],
        },
        {
          onSuccess: handleGoNext,
        }
      );
    } else if (entity.type === 'package' || entity.type === 'dropoffBag') {
      await acknowledgeItemInstructionsBatch(
        {
          instructionIds: activeOrderInstruction?.ids ?? [],
        },
        {
          onSuccess: handleGoNext,
        }
      );
    }
  };

  const handleVerify = async (isFollowed: boolean) => {
    const activeOrderInstruction = orderInstructions[activeStep];
    if (entity.type === 'order') {
      await verifyOrderInstruction(
        { orderId: entity.orderId, bagCode: entity.bagCode, instructionId: activeOrderInstruction?.id, reason: selectedReason, isFollowed },
        {
          onSuccess: handleGoNext,
        }
      );
    } else if (entity.type === 'item' || !activeOrderInstruction?.ids?.length) {
      await verifyItemInstructionsBatch(
        {
          instructionIds: [activeOrderInstruction?.id],
          reason: selectedReason,
          isFollowed,
        },
        {
          onSuccess: handleGoNext,
        }
      );
    } else if (entity.type === 'package' || entity.type === 'dropoffBag') {
      await verifyItemInstructionsBatch(
        {
          instructionIds: activeOrderInstruction?.ids ?? [],
          reason: selectedReason,
          isFollowed,
        },
        {
          onSuccess: handleGoNext,
        }
      );
    }
  };

  const handleGoNext = () => {
    setSettingReason(false);
    setSelectedReason('');
    setSelectedImage(0);
    if (activeStep < orderInstructions.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setActiveStep(0);
      onClose();
    }
  };

  const activeOrderInstruction = orderInstructions[activeStep];

  return (
    <CustomDialog custom_size={{ height: '93%', width: '73%' }} open={open} size={false}>
      {!settingReason ? (
        <Wrapper container direction="column" alignItems="center" wrap="nowrap">
          <TitleWrapper container item direction="row" justifyContent="center" alignItems="center">
            <DialogTitle variant="h4" display="initial" align="center">
              {stage === 'acknowledge' ? 'Customer Instruction' : stage === 'verify' ? 'Was the instruction followed?' : ''}
            </DialogTitle>
          </TitleWrapper>
          <StyledDivider orientation="horizontal" />
          {/* TODO: Show Details */}
          {withDetails && activeOrderInstruction?.linkedItem && (
            <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="row" width={'100%'}>
              <ItemDetailWrapper>
                <Typography variant="h6" display="initial" align="left" color="textSecondary">
                  {'Item Type'}
                </Typography>
                <Typography variant="h6" display="initial" align="right">
                  {activeOrderInstruction.linkedItem?.itemType}
                </Typography>
              </ItemDetailWrapper>
              <ItemDetailWrapper>
                <Typography variant="h6" display="initial" align="left" color="textSecondary">
                  {'Brand'}
                </Typography>
                <Typography variant="h6" display="initial" align="right">
                  {activeOrderInstruction.linkedItem?.itemBrand}
                </Typography>
              </ItemDetailWrapper>
              <ItemDetailWrapper>
                <Typography variant="h6" display="initial" align="left" color="textSecondary">
                  {'Color'}
                </Typography>
                {/** TODO: Update colors rendering */}
                <ColorMapper colorsList={activeOrderInstruction.linkedItem.itemColors ?? []} textHidden />
              </ItemDetailWrapper>
              <ItemDetailWrapper />
            </Box>
          )}
          <Grid item container direction="row" style={{ flexBasis: '60%' }}>
            {!!orderInstructions[activeStep]?.images?.length && (
              <ImagesWrapper item xs={7} container>
                {/* Main Image */}
                <Grid container item xs={12} className={`hero-img-wrapper`}>
                  <Box className={'sa-image'} display="flex" justifyContent="center" alignItems="center">
                    <img
                      src={orderInstructions[activeStep]?.images?.[selectedImage] || ''}
                      alt={'Customer Special Request Item - Images'}
                      className={'sa-item-image sa-hero-image'}
                      ref={heroImgRef}
                      onError={() => (heroImgRef.current!.src = NoImage)}
                    />
                  </Box>
                </Grid>

                {/* Images Thumbnail  */}
                {(orderInstructions[activeStep]?.images?.length || 0) > 1 && (
                  <ThumbnailImgsWrapper container item xs={12} wrap="nowrap">
                    {orderInstructions[activeStep]?.images?.reduce((prev, imgSrc, idx) => {
                      if (idx === selectedImage) return prev;
                      const imgRef = React.createRef<HTMLImageElement>();
                      prev.push(
                        <Grid key={imgSrc} container item xs={4} justify="center" alignItems="center" className={'thumbnail-image'}>
                          <Box className={'sa-image'} display="flex" justifyContent="center" alignItems="center">
                            <ButtonBase
                              onClick={() => {
                                setSelectedImage(idx);
                              }}
                              focusRipple
                            >
                              <img
                                src={imgSrc || ''}
                                alt={'Customer Special Request Item - Images'}
                                ref={imgRef}
                                onError={() => (imgRef.current!.src = NoImage)}
                                className={'sa-item-image'}
                              />
                            </ButtonBase>
                          </Box>{' '}
                        </Grid>
                      );
                      return prev;
                    }, [] as JSX.Element[])}
                  </ThumbnailImgsWrapper>
                )}
              </ImagesWrapper>
            )}

            <InstructionsWrapper container xs={orderInstructions[activeStep]?.images?.length ? 5 : 12} item direction="column">
              <InstructionTitle display="initial" align="left">
                {orderInstructions[activeStep]?.baseInstructionType}
              </InstructionTitle>

              <StyledNotes display="initial" align="left">
                {orderInstructions[activeStep]?.description}
              </StyledNotes>
            </InstructionsWrapper>
          </Grid>
          <StepperWrapper>
            <StyledStepper
              nextButton={<></>}
              backButton={<></>}
              variant="dots"
              steps={orderInstructions.length || 0}
              position="static"
              activeStep={activeStep}
            />
          </StepperWrapper>
          {stage === 'acknowledge' ? (
            <Grid item>
              <StyledButton size={'large'} color="primary" variant="contained" disabled={isMutating} onClick={handleAcknowledge}>
                Next
              </StyledButton>
            </Grid>
          ) : stage === 'verify' ? (
            <ButtonsWrapper item container justifyContent="center">
              <StyledButton size={'large'} color="secondary" variant="contained" onClick={() => setSettingReason(true)}>
                No
              </StyledButton>
              <StyledButton size={'large'} color="primary" variant="contained" disabled={isMutating} onClick={() => handleVerify(true)}>
                Yes
              </StyledButton>
            </ButtonsWrapper>
          ) : (
            <></>
          )}
        </Wrapper>
      ) : (
        <Wrapper container direction="column" alignItems="center" wrap="nowrap">
          <TitleWrapper container item direction="row" justifyContent="center" alignItems="center">
            <DialogTitle variant="h4" display="initial" align="center">
              Reason for Not Following the Instruction
            </DialogTitle>
          </TitleWrapper>

          <StyledToggleButtonGroup
            value={selectedReason}
            exclusive
            onChange={(_e: React.MouseEvent<HTMLElement>, v: string) => setSelectedReason(v)}
            aria-label="text formatting"
          >
            {orderInstructions[activeStep]?.notFollowedReasons.map((value) => (
              <ToggleButton key={value} value={value} aria-label={value}>
                {startCase(value?.toLowerCase())}
              </ToggleButton>
            ))}
          </StyledToggleButtonGroup>

          <ButtonsWrapper item container justifyContent="center">
            <StyledButton
              size={'large'}
              color="primary"
              variant="outlined"
              onClick={() => {
                setSettingReason(false);
                setSelectedReason('');
              }}
            >
              Cancel
            </StyledButton>
            <StyledButton
              size={'large'}
              disabled={!selectedReason || isMutating}
              color="primary"
              variant="contained"
              onClick={() => handleVerify(false)}
            >
              Next
            </StyledButton>
          </ButtonsWrapper>
        </Wrapper>
      )}
    </CustomDialog>
  );
};

export default InstructionsDialog;

const StyledDivider = styled(Divider)`
  margin-left: -1rem;
  margin-right: -1rem;
  width: -webkit-fill-available;
`;

const StyledStepper = styled(MobileStepper)`
  background-color: transparent !important;
  flex-grow: 1;
  .MuiMobileStepper-dot {
    width: 1rem;
    height: 1rem;
    margin: 0 0.5rem;
  }
`;
const StepperWrapper = styled(Box)`
  text-align: -webkit-center;
  margin: 2.5rem 0;
`;
const Wrapper = styled(Grid)`
  padding: 1rem;
  flex-wrap: nowrap;
  height: 100%;
`;

const StyledNotes = styled(Typography)`
  font-size: 1.25rem !important;
  font-weight: 300 !important;
  max-height: 20rem;
  overflow-y: auto;
`;

const ButtonsWrapper = styled(Grid)`
  gap: 2rem;
`;

const InstructionsWrapper = styled(Grid)`
  padding: 1rem;
  gap: 2rem;
`;

const TitleWrapper = styled(Grid)`
  padding: 1rem;
  position: relative;
  margin-bottom: 2rem !important;
`;

const ImagesWrapper = styled(Grid)`
  .hero-img-wrapper {
    gap: 1rem;
    padding: 1rem;
    min-height: 85%;
  }

  .thumbnail-image {
    min-width: 7rem;
    padding: 0px 10px;
  }

  .sa-item-image {
    max-width: 100%;
    display: block;
    max-height: 3rem;
  }

  .sa-hero-image {
    max-height: 22rem;
  }

  .sa-image {
    position: relative;
  }
`;

const ThumbnailImgsWrapper = styled(Grid)`
  overflow-x: auto;
  max-width: 25rem;
  padding: 1rem;
`;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3.5rem;
  overflow-y: auto;
  flex-basis: 75%;
  margin-bottom: 2rem;

  .MuiToggleButtonGroup-groupedHorizontal:not(:last-child),
  .MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
    height: 12.5rem;
    width: 21.8rem;
    margin-top: 1rem;
    border: 3px solid #b6bcd1;
    border-radius: 5px;
    margin-left: 0;
    text-transform: capitalize;
  }
  .MuiToggleButton-root.Mui-selected + .MuiToggleButton-root.Mui-selected,
  .MuiToggleButton-root.Mui-selected {
    color: #3e48a6;
    background-color: #ffffff;
    border-color: #3e48a6;
    border: 3px solid #3e48a6;
  }
`;

const ItemDetailWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
`;

const InstructionTitle = styled(Typography)`
  font-size: 2.25rem !important;
  font-weight: 500 !important;
`;
const DialogTitle = styled(Typography)`
  font-size: 3rem !important;
  font-weight: 400 !important;
`;
