import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';

import WidgetBox from 'components/widgetBox/widgetBox';
import SorterDialog from './sorterDialog';

import Stepper from './customStepper/customStepper';
import { Grid, Button, makeStyles, Theme, createStyles, Typography, Select, MenuItem, IconButton, Box } from '@material-ui/core';
import { Route, useNavigate, Routes, Outlet } from 'react-router-dom';

import { InstructionActionTypes, SorterSteps, StationTypes } from 'utils/enums/stationEnums';
import { observer } from 'mobx-react-lite';
import { useStationStore } from 'stores';
import { ReactComponent as CheckMarkIcon } from 'assets/images/svgs/checkmark.svg';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { CustomDialog, ValidateManagerDialog } from 'components/dialogs';
import { LinkSaItemsDialog } from '../components/dialogs';
import { Toaster } from 'components/toaster';

import { ReactComponent as LinkSVG } from 'assets/images/svgs/specialAttentionSVG/link.svg';
import { ReactComponent as UnlinkSVG } from 'assets/images/svgs/specialAttentionSVG/unlink.svg';
import { SpecialAttentionImage } from 'components/specialAttentionImageGallery';
import { IObservableValue } from 'mobx';
import { APPROVAL_STATUS, IItemGroup, IOrderItem, ItemTypes, ListInstructionsOptions, OrderServiceLines } from 'utils/interfaces';
import useRequestApprovalSendToCp from '../mutations/useRequestApprovalSendToCp';
import styled from 'styled-components';
import useVerifyStation from '../hooks/useVerifyStation';
import { Close } from '@material-ui/icons';
import MarryingModal from './marrying/marryingModal';
import useGetItemGroups from 'modules/stations/components/itemGroups/queries/useGetItemGroups';
import useGetRecommendation from '../../components/itemGroups/queries/useGetRecommendation';
import { MatchRecommendedModal } from '../../components/itemGroups/MatchRecommendedModal';
import useAddGroup from '../../components/itemGroups/mutations/useAddGroup';
import { ItemsMatchedModal } from '../../components/itemGroups/ItemsMatchedModal';
import AnswerDelayComponent from 'modules/stations/pendingItemsStation/components/answerDelays/answerDelayComponent';
import { ItemDetails, useAnswerDelays } from 'modules/stations/pendingItemsStation/context';
import { useCreateNewItem } from 'modules/stations/shoeSorterStation/mutations';

import { ReactComponent as MatchButton } from 'assets/images/svgs/match-button.svg';
import { ReactComponent as MatchedIcon } from 'assets/images/svgs/linked-icon.svg';
import UniversalItemNoteModal from 'components/dialogs/UniversalItemNoteModal';
import useUniversalItemNotesState from 'hooks/useUniversalItemNotesState';
import InstructionsDialog from 'components/dialogs/instructionsDialog';
import useInstructionsDlg from 'hooks/useInstructionsDlg';
import { linkActions } from 'modules/stations/components/saDialog/saDialog';

interface Props {
  hideDefaultBtns?: boolean;
  autoSave?: boolean;
  noManagerRequired?: boolean;
  disabledSteps?: number[];
  disableMarrying?: boolean;
  instructionsOptions?: Pick<ListInstructionsOptions, 'appliedTo'>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: '1',
    },
    iconMargin: {
      marginLeft: '2rem',
    },
    buttonStyle: {
      width: '100%',
      padding: '1.5rem 0 !important',
    },
    floatingImage: {
      [theme.breakpoints.down('md')]: {
        maxHeight: '80px',
      },
      [theme.breakpoints.up('md')]: {
        maxHeight: '100px',
      },
    },
  })
);

const StepsState: React.FC<React.PropsWithChildren<Props>> = observer(
  ({
    disabledSteps = [],
    hideDefaultBtns = false,
    autoSave = false,
    noManagerRequired = false,
    children,
    disableMarrying = false,
    instructionsOptions,
  }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const isWfSorter = useVerifyStation(StationTypes.WFSorter);
    const { mutateAsync: requestApprovalSendToCp } = useRequestApprovalSendToCp();
    const { mutateAsync: createNewItem } = useCreateNewItem();

    const [sendToCpDialogOpen, setSendToCpDialogOpen] = useState(false);
    const [isMatchModalOpen, setIsMatchModalOpen] = useState<boolean>(false);
    const [isMatchedModalOpen, setIsMatchedModalOpen] = useState<boolean>(false);
    const { handleItemDelay, answerNoDelay } = useAnswerDelays();

    const { mutate: addGroup } = useAddGroup();

    const handleCreateNewItem = async (item: IOrderItem) => {
      await createNewItem(
        { rfid: item.rfid, item: { ...item } },
        {
          onSuccess: (item) => {
            toast(<Toaster type="error" message="Please try to sort again" />);
          },
          onSettled: () => {
            setSortingDisabled(false);
          },
        }
      );
    };

    // Import Context
    const {
      currentItem,
      updateItemRequest,
      markItemAsSorted,
      removeItemFromBag,
      linkedItem,
      isAllSaItemsLinked,
      focusInput$,
      hasSpecialAttentionItems,
      bag,
      specialItemsInBag,
      specialAttentionItemsList,
      linkSpecialAttentionItemToBag,
      unlinkSpecialAttentionItemFromBag,
      unlinkItemToSpecialAttentionItem,
      linkItemToSpecialAttentionItem,
      setCurrentItem,
      stationId,
    } = useStationStore();
    const [isManagerDialogOpen, setIsManagerDialogOpen] = useState(false);
    const [showMarryingModel, setShowMarryingModel] = useState(false);

    const {
      acknowledgementModalOpen,
      verificationModalOpen,
      verificationOrderInstructions,
      openAcknowledgementModal,
      openVerificationModal,
      closeAcknowledgementModal,
      refetchVerificationItemInstruction,
      nextVerificationInstruction,
      refetchAcknowledgementItemInstruction,
      acknowledgementItemInstruction,
    } = useInstructionsDlg({
      entity: {
        type: 'item',
        itemCode: currentItem.code,
        orderId: currentItem.orderId || bag.orderId,
      },
      stationId: stationId,
      instructionsOptions,
      onVerificationModalClose: () => {
        if (!hideDefaultBtns) {
          navigate('../', { replace: true });
        }
      },
    });

    useEffect(() => {
      openAcknowledgementModal();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentItem.code, currentItem.orderId, acknowledgementItemInstruction]);

    useEffect(() => {
      if (hideDefaultBtns) {
        openVerificationModal();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hideDefaultBtns, currentItem.code, currentItem.orderId]);

    const btnEnabled =
      currentItem.type &&
      currentItem.brand &&
      currentItem.colorsList &&
      currentItem.colorsList.length > 0 &&
      currentItem.programs &&
      currentItem.frontImage;

    const [sortingDisabled, setSortingDisabled] = useState(false);
    const [resetDelay, setResetDelay] = useState(false);
    const toggleResetDelay = (value: boolean) => setResetDelay(value);

    const {
      noteForStation,
      open: openUniversalItemNotesModal,
      setOpen: setOpenUniversalItemNotesModal,
    } = useUniversalItemNotesState(stationId, currentItem);

    const orderItemDetails = useMemo<ItemDetails>(() => {
      return {
        itemId: currentItem.code ?? '',
        orderAlphaId: currentItem.orderAlphaId ?? '',
        customerAlphaId: currentItem.customerAlphaId ?? '',
        dropoffDate: currentItem.dropoffDate ?? '',
        itemType: currentItem.type as ItemTypes | undefined,
        orderId: currentItem.orderId ?? '',
      };
    }, [currentItem]);

    const handleDelayAnswer = (isDelayed: boolean) => {
      if (orderItemDetails.itemId && currentItem.orderId) {
        if (isDelayed) {
          handleItemDelay?.({ ...orderItemDetails });
        } else {
          answerNoDelay?.({ ...orderItemDetails });
        }
        toggleResetDelay(false);
      }
    };

    const { itemGroups, refetch: refetchItemGroups } = useGetItemGroups(
      bag.orderAlphaId,
      [],
      true,
      OrderServiceLines.LAUNDRY,
      [],
      disableMarrying ? !disableMarrying : !!btnEnabled
    );
    const itemGroup = useMemo(() => {
      const group = itemGroups.groups.find((group) => {
        if (!_.isEmpty(group.itemCodesList)) {
          return group.itemCodesList.includes(currentItem.code);
        }
        return false;
      });
      if (group) {
        return itemGroups.items.filter((item) => item.itemCode && group.itemCodesList.includes(item.itemCode));
      }
    }, [currentItem, itemGroups]);

    function onStepClick(step: SorterSteps) {
      if (step === SorterSteps.stainDamage) {
        if (currentItem.reportDetailsList && currentItem.reportDetailsList.length > 0) {
          navigate(`(item/${step})/overView`);
        } else {
          navigate(`(item/${step})`);
        }
        return;
      }
      navigate(`(item/${step})`);
    }
    function handleClose() {
      navigate('', { replace: true });
    }

    async function handleSortItem() {
      try {
        setSortingDisabled(true);
        try {
          await updateItemRequest(currentItem);
          await markItemAsSorted();
          const { data: newVerifications } = await refetchVerificationItemInstruction();
          if (newVerifications?.length) {
            openVerificationModal();
          } else {
            navigate('../', { replace: true });
          }
        } catch (error) {
          setSortingDisabled(false);
          const infoCode = Object.keys(_.get(error, 'response.data.infoCodes', ['']))[0];

          if (infoCode === '404.21') {
            setSortingDisabled(true);
            await handleCreateNewItem({ ...currentItem });
          }
        }
      } finally {
        setSortingDisabled(false);
      }
    }

    const handleSendToCp = async (reason: string) => {
      setSendToCpDialogOpen(false);
      await requestApprovalSendToCp(
        {
          itemCode: currentItem.code || '',
          orderId: bag.orderId,
          approvalReason: reason,
        },
        {
          onSuccess: () => {
            setCurrentItem({
              ...currentItem,
              approvalStatus: APPROVAL_STATUS.PENDING_WF_TO_CP,
            });
            handleSortItem();
          },
        }
      );
    };

    const handleOpenSendToCpDialog = () => {
      setSendToCpDialogOpen(true);
    };

    const handleAddItem = () => {
      navigate('../');
    };

    // remove item from bag only if item is not sorted and is recurrent
    const handleRemoveItem = (managerId: string = '') => {
      setIsManagerDialogOpen(false);
      removeItemFromBag(managerId);
    };

    const onLinkButtonPressed = async () => {
      // Toggle the unlink dialog
      if (linkedItem) return navigate(`(linkStatus/unlinkItem)`);

      // Toggle link dialog
      navigate(`(linkStatus/linkItem)`);
    };

    const showGroupItems = () => {
      setShowMarryingModel(true);
    };

    const handleMarryingModelClose = () => {
      setShowMarryingModel(false);
    };

    const { recommendedItems, existingGroup } = useGetRecommendation(
      disableMarrying ? !disableMarrying : !!btnEnabled,
      itemGroups as IItemGroup,
      currentItem,
      OrderServiceLines.LAUNDRY
    );

    const linkItems = () => {
      let itemCodesList: string[] = [];
      const itemCodes = [currentItem.code, ...recommendedItems.map((item) => item?.itemCode)];
      itemCodes.forEach((code) => code && itemCodesList.push(code));
      addGroup(
        {
          orderAlphaId: bag.orderAlphaId,
          itemCodesList: itemCodesList,
          groupId: existingGroup && existingGroup.groupId,
        },
        {
          onSuccess: () => {
            refetchItemGroups();
            setIsMatchModalOpen(false);
          },
        }
      );
    };

    const recommendedItemsList = useMemo(() => {
      if (recommendedItems && recommendedItems.length && currentItem.code) {
        const recommendedItemCodes = (recommendedItems as IOrderItem[]).map((item) => ({
          itemCode: item?.itemCode || item?.code,
        }));
        return [...recommendedItemCodes, { itemCode: currentItem.code }];
      }
    }, [recommendedItems, currentItem]);

    return (
      <Routes>
        <Route
          path={'/'}
          element={
            <Wrapper focusInput$={focusInput$} setFocus={true}>
              <WidgetBox>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                  <MarryingButtonGrid xs={'auto'}>
                    <Button color="primary" size="large" variant="text" className={classes.buttonStyle} onClick={showGroupItems}>
                      <span>{'Marrying Items'}</span>
                    </Button>
                  </MarryingButtonGrid>

                  {itemGroup && !_.isEmpty(itemGroup) ? (
                    <Grid xs={'auto'}>
                      <MatchedIcon onClick={() => setIsMatchedModalOpen(true)} />
                      <ItemsMatchedModal
                        currentItem={currentItem}
                        items={itemGroup}
                        onClose={() => setIsMatchedModalOpen(false)}
                        open={isMatchedModalOpen}
                      />
                    </Grid>
                  ) : (
                    !_.isEmpty(recommendedItems) && (
                      <Grid xs={'auto'}>
                        <MatchRecommendedModal
                          open={isMatchModalOpen}
                          currentItem={currentItem}
                          items={recommendedItems}
                          onClose={() => setIsMatchModalOpen(false)}
                          onMatch={linkItems}
                        />
                        <MatchButton onClick={() => setIsMatchModalOpen(true)} />
                      </Grid>
                    )
                  )}

                  {currentItem.isSorted && (
                    <DelayWrapper>
                      <AnswerDelayComponent
                        handleDelayAnswer={handleDelayAnswer}
                        resetDelay={resetDelay}
                        toggleResetDelay={toggleResetDelay}
                        answerDelaysWidth={'40%'}
                        twoButtonOption={false}
                        delayButtonText="Add Delay"
                        delayButtonSize="small"
                      />
                    </DelayWrapper>
                  )}
                </Grid>

                <Grid container direction="column" justify="space-between" alignItems="center" classes={{ container: classes.root }}>
                  <Stepper
                    onStepClick={onStepClick}
                    // disable stains Step
                    disabledSteps={isWfSorter ? [6] : disabledSteps}
                  />

                  <Grid container item direction="row" alignItems="center" justify="space-evenly" spacing={1}>
                    {linkedItem && !hideDefaultBtns && (
                      <Grid container item xs={12}>
                        <Grid container item xs={4} alignItems="center" justify="center" onClick={() => navigate(`(linkStatus/itemOverview)`)}>
                          {/* Render the Image with status and brand */}
                          <SpecialAttentionImage item={linkedItem} dialogClassName={classes.floatingImage} withStatus={false} />
                        </Grid>
                      </Grid>
                    )}
                    {!hideDefaultBtns && (
                      <>
                        {(!isAllSaItemsLinked || linkedItem) && (
                          <Grid item xs={4}>
                            <Button
                              variant="outlined"
                              size="large"
                              className={classes.buttonStyle}
                              color={linkedItem ? 'secondary' : 'primary'}
                              startIcon={
                                linkedItem ? (
                                  <UnlinkSVG style={{ width: '22px', height: '22px' }} />
                                ) : (
                                  <LinkSVG style={{ width: '22px', height: '22px' }} />
                                )
                              }
                              onClick={onLinkButtonPressed}
                            >
                              {linkedItem ? 'Unlink Item' : 'Link item'}
                            </Button>
                          </Grid>
                        )}

                        {currentItem.isSorted && (
                          <Grid item xs={4}>
                            <Button
                              size="large"
                              color="primary"
                              variant="outlined"
                              className={classes.buttonStyle}
                              startIcon={<AddIcon />}
                              onClick={handleAddItem}
                            >
                              Add item
                            </Button>
                          </Grid>
                        )}

                        {!currentItem.isSorted && currentItem.isRecurrent && (
                          <Grid item xs={4}>
                            <Button
                              color="secondary"
                              size="large"
                              variant="contained"
                              className={classes.buttonStyle}
                              startIcon={<DeleteIcon />}
                              onClick={() => setIsManagerDialogOpen(true)}
                            >
                              Remove item
                            </Button>
                          </Grid>
                        )}

                        <Grid item xs={4}>
                          {!isWfSorter || (isWfSorter && currentItem.approvalStatus) ? (
                            <Button
                              color="primary"
                              size="large"
                              variant="contained"
                              className={classes.buttonStyle}
                              onClick={handleSortItem}
                              disabled={currentItem.isSorted || !btnEnabled || sortingDisabled}
                            >
                              <span>{currentItem.isSorted ? 'item sorted' : 'sort item'}</span>
                              {currentItem.isSorted && <CheckMarkIcon className={classes.iconMargin} />}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              size="large"
                              variant="contained"
                              className={classes.buttonStyle}
                              onClick={handleOpenSendToCpDialog}
                              disabled={!btnEnabled}
                            >
                              Send to CP
                            </Button>
                          )}
                        </Grid>
                      </>
                    )}
                    {children}
                  </Grid>
                </Grid>
                <MarryingModal
                  open={showMarryingModel}
                  onClose={handleMarryingModelClose}
                  orderAlphaId={bag.orderAlphaId}
                  currentItem={currentItem}
                  recommendedItems={recommendedItemsList}
                  disableMarrying={disableMarrying}
                />
              </WidgetBox>
              <SendToCpDialog onClose={() => setSendToCpDialogOpen(false)} open={sendToCpDialogOpen} handleSubmit={handleSendToCp} />
              <ValidateManagerDialog
                onClose={() => setIsManagerDialogOpen(false)}
                open={isManagerDialogOpen}
                onManagerScan={(isValid, managerId) => handleRemoveItem(managerId)}
                autoValidate={false}
              />
              {noteForStation && (
                <UniversalItemNoteModal
                  note={noteForStation}
                  onClose={() => setOpenUniversalItemNotesModal(false)}
                  open={openUniversalItemNotesModal}
                />
              )}
              <InstructionsDialog
                open={acknowledgementModalOpen}
                entity={{ type: 'item', itemCode: currentItem.code, orderId: currentItem.orderId }}
                stage="acknowledge"
                onClose={closeAcknowledgementModal}
                orderInstructions={acknowledgementItemInstruction || []}
              />
              <InstructionsDialog
                open={verificationModalOpen && !acknowledgementModalOpen}
                entity={{ type: 'item', itemCode: currentItem.code, orderId: currentItem.orderId }}
                stage="verify"
                onClose={nextVerificationInstruction}
                orderInstructions={verificationOrderInstructions}
              />
              <Outlet />
            </Wrapper>
          }
        >
          <Route
            path={`(item/:dialogType)/*`}
            element={
              <Wrapper focusInput$={focusInput$} setFocus={false}>
                <SorterDialog onClose={handleClose} open={true} autoSave={autoSave} noManagerRequired={noManagerRequired} />
              </Wrapper>
            }
          />

          {/* Linking / Unlinking Item Dialog */}
          <Route
            path={`(linkStatus/:linkStatus)/*`}
            element={
              <Wrapper focusInput$={focusInput$} setFocus={false}>
                <LinkSaItemsDialog
                  currentItem={currentItem}
                  onCloseHandler={() => navigate('../')}
                  onSuccessfulLinkHandler={(action) => {
                    if (action !== linkActions.unlinkSuccessful) {
                      refetchAcknowledgementItemInstruction();
                    }
                  }}
                  isOpen={true}
                  saDialogProps={{
                    hasSpecialAttentionItems,
                    bag,
                    linkedItem,
                    specialItemsInBag,
                    specialAttentionItemsList,
                    linkSpecialAttentionItemToBag,
                    unlinkSpecialAttentionItemFromBag,
                    unlinkItemToSpecialAttentionItem,
                    linkItemToSpecialAttentionItem,
                  }}
                />
              </Wrapper>
            }
          />
        </Route>
      </Routes>
    );
  }
);

export default StepsState;

const Wrapper: React.FC<
  React.PropsWithChildren<{
    setFocus: boolean;
    focusInput$: IObservableValue<boolean>;
  }>
> = observer(({ children, focusInput$, setFocus }) => {
  focusInput$.set(setFocus);
  return <>{children}</>;
});

/** For the status `Item ONLY suitable for CP` it's important to keep it as is, as this is manually being checked on Customer Portal */
const APPROVAL_REASONS: string[] = ['Item not suitable for 40C', 'Item at Risk', 'Item needs special care', 'Item ONLY suitable for CP'];
const SendToCpDialog: React.FC<{ open: boolean; handleSubmit: (reason: string) => void; onClose: () => void }> = ({
  open,
  handleSubmit,
  onClose,
}) => {
  const [selectedReason, setSelectedReason] = useState('');
  return (
    <CustomDialog open={open} size="lg">
      <DialogHeader container justifyContent="flex-end" item xs={12}>
        <IconButton onClick={onClose}>
          <StyledCloseIcon />
        </IconButton>
      </DialogHeader>
      <Grid container direction="column" justifyContent="center" alignItems="center" style={{ padding: '0 1rem 6rem 1rem', gap: '4rem' }}>
        <Grid item>
          <Typography variant="h3">Choose a reason to transfer item to CP</Typography>
        </Grid>
        <Grid item>
          <Select
            variant="outlined"
            style={{ width: '20rem' }}
            displayEmpty
            value={selectedReason}
            onChange={(v) => setSelectedReason(v.target.value as string)}
          >
            <StyledMenuItem value="">
              <em>Please select</em>
            </StyledMenuItem>
            {APPROVAL_REASONS.map((reason) => (
              <StyledMenuItem key={reason} value={reason}>
                {reason}
              </StyledMenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item style={{ paddingTop: '6rem' }}>
          <Button disabled={!selectedReason} variant="outlined" color="primary" size="large" onClick={() => handleSubmit(selectedReason)}>
            Submit Reason
          </Button>
        </Grid>
      </Grid>
    </CustomDialog>
  );
};

const StyledMenuItem = styled(MenuItem)`
  font-size: 2rem;
  height: 3rem;
`;

const DialogHeader = styled(Grid)`
  flex: 0;
`;
const StyledCloseIcon = styled(Close)`
  width: 5rem;
  height: 5rem;
  color: black;
`;

export const MarryingButtonGrid = styled(Grid)`
  margin-right: 2rem;
`;

const DelayWrapper = styled(Box)`
  font-size: 2.5rem;

  svg {
    width: 2.5rem;
    height: 2.5rem;
  }
`;
