import { useMutation } from 'react-query';
import { StationService } from 'services';

const useCanInformAndProceedDelicateItemsOnWasher = () => {
  return useMutation<string, Error, { rfidTag: string }>({
    mutationFn: ({ rfidTag }) => StationService.canInformAndProceedDelicateItemsOnWasher(rfidTag),
  });
};

export { useCanInformAndProceedDelicateItemsOnWasher };
