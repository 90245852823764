import { OriginApps } from 'utils/maps/originApps';
import { BAG_STATUSES, BagTypes, FoldingType, InstructionActionTypes, ItemPackagingStatus, PackageStatuses } from '../enums/stationEnums';
import { IPackage, OrdersGroup, PickupAddress } from './orderInterface';
import { DelayInfo, DelayReasons } from './pendingStationInterfaces';
import { HTMLInputTypeAttribute } from 'react';

export type ReportType = 'damage' | 'stain';
export type ItemTypes =
  | 'SHIRT'
  | 'T_SHIRT'
  | 'BLOUSE'
  | 'SWEATER'
  | 'PULLOVER'
  | 'SHORTS'
  | 'PANTS'
  | 'SKIRT'
  | 'BATHROBE'
  | 'COAT'
  | 'JACKET'
  | 'GATHRA'
  | 'SUIT2PC'
  | 'SUIT_PANTS'
  | 'SUIT_JACKET'
  | 'LUNGI'
  | 'SCARF'
  | 'TIE'
  | 'VEST'
  | 'OVERCOAT'
  | 'SARI'
  | 'SHERWANI'
  | 'DRESS_SHORT'
  | 'BATHING_SUIT'
  | 'PYJAMA_PANTS'
  | 'PYJAMA_FULL'
  | 'JUMPSUIT'
  | 'SIRWAL'
  | 'KURTA_MENS'
  | 'KURTA_WOMENS'
  | 'DRESS_LONG'
  | 'WINTER_JACKET'
  | 'WINTER_OVERCOAT'
  | 'ABAYA'
  | 'KANDURA'
  | 'CAP'
  | 'UNDERWEAR'
  | 'SOCKS'
  | 'BRA'
  | 'BEDSHEET'
  | 'PILLOW_CASE'
  | 'TOWEL'
  | 'COMFORTER'
  | 'COMFORTER_COVER'
  | 'MATTRESS_PROTECTOR'
  | 'DUVET'
  | 'TABLE_MAT'
  | 'LOOSE_ITEM'
  | 'ACCESSORY'
  | 'JALABIYA'
  | 'DELICATE_ABAYA'
  | 'FACE_MASK'
  | 'GLOVE'
  | 'TABLE_CLOTH'
  | 'PILLOW'
  | 'BLANKET'
  | 'BLANKET_LARGE'
  | 'BEDCOVER'
  | 'SHEILA'
  | 'CURTAIN_SQM'
  | 'THICK_CURTAINS'
  | 'CUSHION_COVER_SMALL'
  | 'BELT'
  | 'UNDERSHIRT'
  | 'LINEN'
  | 'SOFA_COVER_REGULAR'
  | 'SOFA_COVER_LARGE'
  | 'CUSHION_COVER_MEDIUM'
  | 'CUSHION_COVER_LARGE'
  | 'CARPET_REGULAR_PERSQM'
  | 'CARPET_WOOL'
  | 'DUFFEL_BAG'
  | 'BACKPACK'
  | 'DOORMAT'
  | 'RUG'
  | 'HANDKERCHIEF'
  | 'DRESS_WITH_BELT'
  | 'JEANS'
  | 'BLOUSE_WITH_BELT'
  | 'SPORTS_SNEAKERS_SYTHENTICS_TEXTILE'
  | 'SPORTS_SNEAKERS_SUEDE_NUBUCK'
  | 'SPORTS_SNEAKERS_LEATHER'
  | 'SPORTS_SNEAKERS_MIX'
  | 'SPORTS_SNEAKERS_CHILDREN'
  | 'DESIGNER_SNEAKERS_SYTHENTICS_TEXTILE'
  | 'DESIGNER_SNEAKERS_SUEDE'
  | 'DESIGNER_SNEAKERS_LEATHER'
  | 'DESIGNER_SNEAKERS_MIX'
  | 'FORMAL_SHOE_SYTHENTICS_TEXTILE'
  | 'FORMAL_SHOE_SUEDE'
  | 'FORMAL_SHOE_LEATHER'
  | 'FORMAL_SHOE_MIX'
  | 'FORMAL_SHOE_CHILDREN'
  | 'BOOTS_SYTHENTICS_TEXTILE'
  | 'BOOTS_SUEDE'
  | 'BOOTS_LEATHER'
  | 'BOOTS_MIX'
  | 'BOOTS_HIGH_BOOTS'
  | 'BOOTS_THIGH_HIGH_BOOTS'
  | 'FLIP_FLOP_SYTHENTICS_TEXTILE'
  | 'FLIP_FLOP_SUEDE'
  | 'FLIP_FLOP_LEATHER'
  | 'FLIP_FLOP_MIX'
  | 'SANDAL_SYTHENTICS_TEXTILE'
  | 'SANDAL_SUEDE'
  | 'SANDAL_LEATHER'
  | 'SANDAL_MIX'
  | 'ESPARDRILLES_SYTHENTICS_TEXTILE'
  | 'ESPARDRILLES_SUEDE'
  | 'ESPARDRILLES_LEATHER'
  | 'ESPARDRILLES_MIX'
  | 'CLUTCH_PURSE'
  | 'WALLETS'
  | 'BAGS_STANDARD_SIZE'
  | 'BAGS_OVERSIZED'
  | 'MENS_BRIEFCASE'
  | 'VALISE_SUITCASE'
  | 'BACKPACKS'
  | 'BELTS'
  | 'APRON'
  | 'STUFFED_TOY_SMALL'
  | 'STUFFED_TOY_MEDIUM'
  | 'STUFFED_TOY_LARGE'
  | 'BRIDAL_DRESS'
  | 'LEATHER_JACKET'
  | 'SLEEVELES_UNDERSHIRT'
  | 'GILET_JACKET'
  | 'CASUAL_DRESS'
  | 'FORMAL_DRESS'
  | 'EVENING_GOWN'
  | 'WAIST_COAT'
  | 'SWIMMING_SUIT'
  | 'PYJAMA_TOP'
  | 'WINTER_PANTS'
  | 'WINTER_COAT'
  | 'TUXEDO_PANTS'
  | 'TUXEDO_JACKET'
  | 'SHOES'
  | 'BAGS'
  | 'VEIL'
  | 'DESIGNER_FORMAL_SHOES_SUEDE'
  | 'SHOE_LACES'
  | 'DRESS_REGULAR'
  | 'DRESS_EVENING'
  | 'DRESS_COMPLEX_EVENING'
  | 'DRESS_COMPLEX'
  | 'DRESS_BASIC'
  | 'SPORT_SWEATER_HOODIE'
  | 'SWEATER_HOODIE'
  | 'THICK_SWEATER_HOODIE'
  | 'HANGERS'
  | 'SUIT_COVER'
  | 'SHOE_TREE'
  | 'SHOE_BOX'
  | 'SHOE_BAG'
  | 'LAUNDRY_TRANSPORT_BAG'
  | 'OTHER'
  | 'DESIGNER_FORMAL_SYTHENTICS_TEXTILE'
  | 'DESIGNER_FORMAL_LEATHER'
  | 'DESIGNER_FORMAL_MIX'
  | 'DESIGNER_SANDALS_FLIPFLOPS'
  | 'BOOTS_ANKLE'
  | 'DESIGNER_ESPARDRILLES'
  | 'REUSABLE_BAG'
  | 'SOFA_COVER'
  | 'CUSHION_COVER'
  | 'CUSHION'
  | 'MENS_FORMAL_SHOE_LEATHER'
  | 'MENS_FORMAL_SHOES_MIXED_MATERIAL'
  | 'MENS_DESIGNER_FORMAL_LEATHER'
  | 'MENS_DESIGNER_FORMAL_MIXED_MATERIAL'
  | 'WOMENS_FORMAL_SHOE'
  | 'WOMEN_DESIGNER_FORMAL';

export type ReportDamageTypes =
  | ''
  | 'Missing Beads'
  | 'Fading Color'
  | 'Loose Threads'
  | 'Iron Mark'
  | 'Zipper Damage'
  | 'Missing Button'
  | 'Chain Damage'
  | 'Color Bleeding'
  | 'Small Holes'
  | 'Pleats Removed'
  | 'Lint'
  | 'Print Damage'
  | 'Print Fading'
  | 'Rip'
  | 'Broken Buttons'
  | 'Other';

export type ReportStainTypes = '' | 'Stain' | 'Hard Stain' | 'Oil Stain' | 'Rust Stain' | 'Sweat Stain' | 'Food Stain' | 'Dirt Stain' | 'Other';

export type ReportLocation = '' | 'Front' | 'side' | 'back' | 'right' | 'left' | 'right' | 'sleeves' | 'left' | 'sleeves' | 'bottom';
export type ReportPart = '' | 'Sleeves' | 'collar' | 'cuffs' | 'legs';
export type ReportArea =
  | ''
  | 'Outer Sole'
  | 'Toe'
  | 'Upper Shoe'
  | 'Tongue'
  | 'Inner Sole'
  | 'Back Heel'
  | 'Womans Heel'
  | 'Laces'
  | 'Eyeletes'
  | 'Buckle';

export interface Garment { }

export interface IOrderItemResponse {
  item: IOrderItem;
  infoCodes?: {
    '210.8': {
      item: {};
    };
  };
}

export interface IOrderItemReportResponse {
  itemReportDetail: IOrderItem;
  infoCodes?: {
    '210.8': {
      item: {};
    };
  };
}

export interface IOrder {
  orderAlphaId: string;
  isUrgent: boolean;
  specialAttentionItemsArray?: SaItem[];
  city: {
    city: string;
  };
  geofence: {
    label: string;
    clusterName: string;
  };
  pickupDriver: {
    name: string;
  };
  dropoffDriver?: {
    name?: string;
    id: string;
  };
  dropoffDate?: string;
  sortedCPBags: number;
  sortedPBags: number;
  sortedHCBags: number;
  totalPBags: number;
  totalCPBags: number;
  totalHCBags: number;
  serviceLine: OrderServiceLines;
  totalSCBags: number;
  sortedSCBags: number;
  totalTFBags: number;
  sortedTFBags: number;
  preferences?: Preferences;
  customerNotes?: '';
  customerAttachmentsList?: string[];
  ordersGroup?: OrdersGroup;
  pickupDate?: string;
  dropoffAddress?: { geofence_label: string; clusterName: string; geofenceLabel: string; cityLabel: string };
  customerAlphaId?: string;
  originApp: OriginApps;
  customerName?: string;
  orderId?: string;
  createdAt?: string;
  dropoffTime?: string;
  pickupTime?: string;
  requestCallback?: string;
  itemsList: IOrderItem[];
  isQuick?: boolean;
  dropoffDurationText?: string;
  dropoffShortDurationText?: string;
  assessmentStatus?: ORDER_ASSESSMENT_STATUS;
  packagesList: IPackage[];
  isVip?: boolean;
  packageCode: string;
  packageCategory: string;
  status: PackageStatuses;
  lastLocation?: string;
  numberOfItems?: number;
  isManualPaymentFineryOrder?: boolean;
  isFirstOrder?: boolean;
  pickupImageBags?: string[] | string;
  selectedPickupDriver?: {
    name: string;
    id: string;
  };
  selectedDropoffDriver?: {
    name: string;
    id: string;
  };
}

export enum ORDER_ASSESSMENT_STATUS {
  ASSESSED = 2,
  PARTIALLY_ASSESSED = 1,
  NOT_ASSESSED = 0,
}

export interface IBag {
  bagCode: string;
  type: BagTypes;
  isSorted: boolean;
  isTagged: boolean;
  isScanned: boolean;
  addedItemCount: number;
  sortedItemCount: number;
  reference: string;
  orderId: string;
  preferences?: Preferences;
  orderAlphaId: string;
  customerAlphaId: string;
  orderItemList?: IOrderItem[];
  customerId?: string;
  driverInstruction?: string;
  customerInstruction?: string;
  order: IOrder;
  specialAttentionItemsList?: SaItem[];
  totalPBags?: number;
  totalCPBags?: number;
  totalHCBags?: number;
  sortedHCBags?: number;
  sortedPBags?: number;
  sortedCPBags?: number;
  isCustomerVip?: boolean;
  bagNumber?: number;
  statusTime?: number;
  bagStatus?: BAG_STATUSES;
  isProblematic?: boolean;
  bagType?: string;
}

export interface IOrderBag {
  bag: IBag;
}

export interface Brand {
  name: string;
  logo: string;
  label: string;
  luxury: boolean;
  Delicate: boolean;
  neckLabel?: string;
  careLabel?: string;
}

export interface ColorsList {
  name: string;
  hex: string;
}

export interface Program {
  name: string;
  key: string;
  digit: string;
}

export interface Programs {
  program: Program;
  dryingProgram?: any;
  subProgram?: any;
}

export interface Preferences {
  driverDropoffInstructions?: {
    atDoorInstructionsArray: string[];
    inPersonInstructionsArray: string[];
  };
  stainDamageApprovals?: {
    isAutoApprove: boolean;
  };
  folding?: string;
  creases?: string;
  starch?: string;
  packaging?: {
    itemsPackagedPerType: {
      SHIRT: number;
    };
    hasCardboard: boolean;
  };
  driverPickupInstructions?: {
    atDoorInstructionsArray: string[];
    inPersonInstructionsArray: string[];
  };
  isCallCustomerBeforeProcessing?: boolean;
  shoeAddOnApprovals?: {
    restoration: boolean;
    soleIcing: boolean;
    stainProtection: boolean;
    assessBeforeProcessing?: boolean;
  };
}

export interface DetailsList {
  location: ReportLocation;
  type: ReportStainTypes | ReportDamageTypes;
  imageUrl: string;
  part?: ReportPart;
  reason: 'DAMAGED' | 'STAINED' | '';
  issueId?: string;
  isActionable?: boolean;
  isRemoved?: boolean;
  side?: 'RIGHT' | 'LEFT';
  area?: ReportArea;
}

export interface IUserNote {
  id?: string;
  description: string;
  photo: string;
  itemCode: string;
  orderAlphaId: string;
  createdAt?: number;
  createdBy?: string;
  updatedAt?: number;
  updatedBy?: string;
  isRemoved: boolean;
  originApp?: NotesOriginApps;
}

export interface UniversalItemNote {
  id: string;
  description: string;
  createdAt?: string;
  createdBy?: string;
  isRemoved: boolean;
  station: string[];
}

export enum NotesOriginApps {
  FACILITY = 'FACILITY',
  OPS = 'OPS',
}

export interface Bucket {
  number?: string;
}

export enum ActivityEntityTypes {
  package = 'package',
}

export interface IActivity {
  userId?: string;
  userName?: string;
  stationId?: string;
  activity: string;
  activityStatus?: number;
  createdAt: number;
  userTimestamp?: string;
  packageCode?: string;
  itemCode?: string;
  entityType?: ActivityEntityTypes;
}

export interface ShoeSize {
  ukSize?: number;
  euSize?: number;
  handbagSize?: string;
}

/**
 * @description
 *  Finery Images, this should be fixed later to have a generic orderItem based on the serviceLine
 * but that would require a lot of changes across stations
 *
 * For now, for Finery, we will use these images below, with the following attributes being required
 * even though they're set as optional : `front` && `back` && `careLabel`
 */
export interface FineryAngleImages {
  front?: string;
  back?: string;
  careLabel?: string;
  leftSide?: string;
  rightSide?: string;
  extra?: string;
}

/**
 * @description
 *  Shoe Images, this should be fixed later to have a generic orderItem based on the serviceLine
 * but that would require a lot of changes across stations
 *
 * For now, for Shoes, we will use these images below, with the following attributes being required
 * even though they're set as optional : `top`
 */
export interface ShoeAngleImages extends FineryAngleImages {
  top?: string;
  backFront?: string;
  bottom?: string;
  inside?: string;
  outside?: string;
  customer?: string;
}

export interface ShoeAngleImagesCleaned {
  customer: string;
}

export enum APPROVAL_STATUS {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  PENDING_WF_TO_CP = 'PENDING_WF_TO_CP',
  PROCEED_CP = 'PROCEED_CP',
  PROCEED_WF = 'PROCEED_WF',
  PROCEED_CP_PENDING = 'PROCEED_CP_PENDING',
}

export type ExtraItem = { code: string; type: ItemTypes; frontImage: string; colorsList?: ColorsList[]; brand?: Brand };

export interface IOrderItem {
  id: string;
  orderId: string;
  orderAlphaId: string;
  customerAlphaId: string;
  customerId: string;
  customerName: string;
  customerNotes: string;
  customerAttachmentsList: string[];
  bagCode: string;
  code: string;
  approvalStatus?: string;
  selectedDropoffDriver?: string;
  approvalOrigin?: string;
  //itemCode added because of response alteration (temporary)
  itemCode?: string;
  brand?: Brand;
  type?: ItemTypes;
  facilityVisits: number;
  frontImage?: string;
  colorsList?: ColorsList[];
  programs?: Programs;
  preferences?: Preferences;
  reportDetailsList?: DetailsList[];
  customerInstruction?: string;
  isHung: boolean;
  isBanned: boolean;
  isSorted?: boolean;
  dropoffAddress?: PickupAddress;
  isRecurrent: boolean;
  cleanType: string;
  careLabelImageUrl?: string;
  isFirstTimePassedByPressing?: boolean;
  isRejected: boolean;
  isReclean?: boolean;
  bucket?: Bucket;
  assignedRack?: number;
  isOrderCompleted?: number;
  rfid: string;
  specialAttentionItemsArray?: SaItem[];
  isPackaged?: boolean;
  packageCode?: string;
  packagingDetails?: IPackageDetails;
  dropoffDate?: string;
  dropoffTime?: string;
  utcDropoffDate?: string;
  utcDropoffTime?: string;
  isScannedOnFolding?: boolean;
  isManuallyScanned?: boolean;
  isScannedOnPackaging?: boolean;
  packagingStatus?: ItemPackagingStatus;
  activities?: IActivity[];
  shoeSize?: ShoeSize;
  itemImagesMap?: ShoeAngleImages;
  itemImagesAfterCleaningMap?: ShoeAngleImagesCleaned;
  orderServiceLine?: OrderServiceLines;
  isPair?: boolean;
  side?: ShoeSide;
  tray?: ShoeTray;
  addOnServicesList?: AddOnService[];
  lastEvent?: IActivity;
  dropoffBag?: DropoffBag;
  measurementsList?: ItemMeasurements[];
  afterMeasurementsList?: ItemMeasurements[];
  borderColor?: string;
  groupId?: string;
  recommendationId?: number;
  isFirstOrder?: boolean;
  originApp?: OriginApps;
  extraItemsList?: ExtraItem[];
  delayDetails?: DelayInfo;
  universalItemNotes?: UniversalItemNote[];
}

export interface ItemMeasurements {
  key: string;
  value: number | string;
  unit?: string;
  letter: string;
}

export interface ItemTypeMeasurementsConfig {
  measurements: MeasurementConfig[];
  imageUrl: string;
}

export interface MeasurementConfig {
  key: string;
  value: number | string;
  type: Extract<HTMLInputTypeAttribute, 'number' | 'text'>;
  unit?: string;
  letter: string;
  required: boolean;
  min?: number;
}

export interface AddOnService {
  value: string;
  label: string;
  isDelayable: boolean;
}

interface ShoeTray {
  trayCode: number;
  createdAt?: string;
}

export type ShoeSide = 'LEFT' | 'RIGHT' | 'BOTH';

export enum ShoeSideEnum {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  BOTH = 'BOTH',
}

export interface IBrandsResponse {
  active?: boolean;
  logo?: string;
  sort_key?: number;
  name: string;
  luxury: boolean;
  Delicate?: boolean;
}

export interface ITopBrandsResponse {
  customerTop10Brands: IBrandsResponse[];
}

export interface IOrdersListObj {
  createdAt: string;
  dropoffAddress: PickupAddress;
  dropoffDate: number;
  geofenceSequence: number;
  orderAlphaId: string;
  orderId: string;
  pickupDate: number;
  isRacked?: boolean;
  racksList: number[];
  pendingRacksList?: number[];
  numberOfPendingRacks?: number;
}

export interface IActiveItem {
  itemCode: string;
  orderId?: string;
  itemImage?: string;
  dropoffDate?: string;
  orderAlphaId?: string;
  isDelayed?: boolean;
  delayReason?: string;
  delayDays?: number;
}

export interface DelayReasonsList {
  reason: DelayReasons;
}

export interface SaItem {
  id: string;
  imageName?: string;
  brandName: string;
  imageUrl: string;
  stainsArray?: string[];
  cleaningInstruction?: string;
  tagsArray?: string[];
  bagCode?: string;
  itemCode?: string;
  notInBag?: boolean;
  comments?: string;
  isOrderInstruction?: boolean;
  instruction?: OrderInstruction;
}

export interface IPackageDetails {
  packageType: 'FOLDED' | 'HUNG';
  packageCategory: FoldingType;
}

export interface ItemIssue {
  issueId: string;
  reason: string;
  imageUrl: string;
  isActioned: boolean;
  action: ItemActions | '';
  location: ReportLocation;
  type: ReportStainTypes | ReportDamageTypes;
  part?: ReportPart;
  isActionable?: boolean;
  side?: ShoeSide;
  area?: ReportArea;
}

export enum ItemActions {
  CLEANED = 'CLEANED',
  SEND_BACK_TO_THE_SPOTTER = 'SEND_BACK_TO_THE_SPOTTER',
  REWASH = 'REWASH',
  CANT_CLEAN = 'CANT_CLEAN',
  NEED_CUSTOMER_APPROVAL = 'NEED_CUSTOMER_APPROVAL',
  ITEM_AT_RISK = 'ITEM_AT_RISK',
  FIXED = 'FIXED',
  NOT_FIXED = 'NOT_FIXED',
  STILL_WET = 'STILL_WET',
  SMELLS_BAD = 'SMELLS_BAD',
  DAMAGE_AFTER_CLEANING = 'DAMAGE_AFTER_CLEANING',
}

export enum ShoeSelections {
  SHOE_PAIR = 'SHOE_PAIR',
  SHOE_SINGLE = 'SHOE_SINGLE',
  SHOE_LEFT = 'SHOE_LEFT',
  SHOE_RIGHT = 'SHOE_RIGHT',
}

export enum OrderServiceLines {
  LAUNDRY = 'laundry',
  SHOES = 'shoes',
  FINERY = 'finery',
}

export enum ServiceType {
  WF = 'WF',
  CP = 'CP',
  TF = 'TF',
  SC = 'SC',
  ALL = 'ALL',
}

export const BAG_TO_SERVICE_TYPE_MAPPER: {
  [key in BagTypes]: ServiceType;
} = {
  CP: ServiceType.CP,
  P: ServiceType.CP,
  HC: ServiceType.CP,
  WF: ServiceType.WF,
  SC: ServiceType.SC,
  TF: ServiceType.TF,
};

export enum UserRoles {
  TRUCK = 'TRUCK',
  NO_ROLE = '',
}

export enum OrderRequestCallbackEnum {
  ANSWERED = 'answered',
  NOT_ANSWERED = 'not_answered',
  NOT_CALLED = 'not_called',
  NOT_REQUESTED = 'not_requested',
}

export interface DropoffBag {
  bagCode: string;
  orderAlphaId: string;
  bagNumber: number;
  isClosed: boolean;
  isFoldingDelayed?: boolean;
  packagesList: IPackage[];
}

export interface IItemGroup {
  items: IOrderItem[];
  allItems: IOrderItem[];
  groups: IGroup[];
  recommendations: IOrderItem[][];
}

export interface IGroup {
  groupId: string;
  createdAt: string;
  itemCodesList: string[];
}

interface IStationNumbers {
  [key: string]: StationSectionItem[];
}

interface IStationSection {
  i18nKey: string;
  text: string;
  value: string;
  items: StationSectionItem[];
}

type StationSectionItem = {
  i18nKey: string;
  text: string;
  value: string;
};

export interface IStationsList {
  stationSections: IStationSection[];
  stationNumbers: IStationNumbers;
}

export interface OrderInstruction {
  description: string;
  images?: string[];
  itemDetails?: {
    itemTypes?: string[];
    itemColors?: ColorsList[];
    itemBrand?: string;
    itemCode?: string;
  };
  linkedItem?: {
    itemType: string;
    itemColors?: ColorsList[];
    itemBrand?: string;
    itemCode?: string;
  };
  baseInstructionType: string;
  baseInstructionPriority: number;
  notFollowedReasons: string[];
  id: string;
  ids?: string[];
}

export interface ListInstructionsOptions {
  category?: string;
  stationId?: string;
  actionType?: InstructionActionTypes;
  appliedTo?: {
    all?: boolean;
    one?: boolean;
    type?: boolean;
  };
}
