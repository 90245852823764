import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStationStore } from 'stores';
import stationService from 'services/station.service';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';

import {
  IProgramType,
  programMapper,
  programCategories,
  categoryColorMapper,
  suggestedProgramsKeys,
  programsCategories,
  categoryMapper,
  PreSelectedPrograms,
  parseSuggestedPrograms,
} from 'utils/maps/programMapper';

/** Import Components */
import LabelSelector from '../../labelSelector/LabelSelector';
import { ValidateManagerDialog } from 'components/dialogs';
import { ConfirmDialog } from 'components/confirmDialog';
import { ItemBanner } from 'modules/stations/components/itemBanner';
import alert from 'assets/images/pngs/alert.png';
import { SaDialog } from 'modules/stations/components/saDialog';
import { SaDialogTypes } from 'utils/enums/stationEnums';

/** Imports Material UI */
import { Box, Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Programs } from 'utils/interfaces';
import { StationService } from 'services';
import { LoaderComponent } from 'components/loaderComponent';

/** Imports Toaster */
import { toast } from 'react-toastify';
import { Toaster } from 'components/toaster';

const useProgramBoxStyle = makeStyles((theme) => ({
  root: {
    padding: '5px 32px 20px 32px',
  },
  labelTypo: {
    fontWeight: 300,
    paddingBottom: '20px',
  },
  overflowBox: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  rejectBtn: {
    padding: '1rem 1.5rem',
  },
}));

/** PreSelectedMapper Props */
interface PreSelectedMapperProps {
  category: programsCategories;
  notation: suggestedProgramsKeys;
  preSelectedObj: PreSelectedPrograms;
  onToggleClick: (program: string, category: string, preSelected: boolean) => void;
  currentProgram: string;
}

/** PreSelected FC */
const PreSelectedMapper: React.FC<PreSelectedMapperProps> = ({ category, notation, preSelectedObj, currentProgram, onToggleClick }) => {
  // If Undefined return empty Fragment
  if (!preSelectedObj) {
    return <></>;
  }
  return (
    <>
      {preSelectedObj[notation].map((program: IProgramType, i: number) => {
        return (
          <LabelSelector
            key={`${i}-${program.key}-type-mapper`}
            isActive={currentProgram === program.key}
            activeColor={categoryColorMapper[category]}
            onToggleClick={() => onToggleClick(program.key, category, true)}
            width="100%"
            isDashed
            isFilled
            isRounded
          >{`station.sorter.program.${program.key}`}</LabelSelector>
        );
      })}
    </>
  );
};

/**
 * @description gets the item category based on the program
 * @param itemProgram
 * @returns {string} program's category
 */
const getSelectedCategoryFromProgram = (itemProgram: IProgramType) => {
  let selectedCategory = '';
  Object.keys(programMapper).map((category: string) => {
    return Object.keys(programMapper[category]).map((key: any) => {
      if (itemProgram.key === programMapper[category][key].key) {
        selectedCategory = category;
        return category;
      }
      return '';
    });
  });
  return selectedCategory;
};

/**
 * Helper Function that returns true if the program is suggested
 * @param preSelectedObj
 * @param category
 * @param currentSelection
 */
const isSuggested = (preSelectedObj: PreSelectedPrograms, category: programsCategories, program: string) => {
  let isSuggestedFlag: boolean = false;
  const CategoryNotation = categoryMapper[category];

  // Make sure that the Array has been loaded and that it's not empty
  if (preSelectedObj[CategoryNotation] && preSelectedObj[CategoryNotation].length > 0 && program)
    // Map the Array to retrieve the program's keys
    preSelectedObj[CategoryNotation].map((programs: IProgramType) => {
      return programs.key === program ? (isSuggestedFlag = true) : null;
    });
  return isSuggestedFlag;
};

interface Props {}

interface Props {
  autoSave?: boolean;
  noManagerRequired?: boolean;
}
type PreSelectedProgramsType = {
  WC: IProgramType[];
  L: IProgramType[];
  DC: IProgramType[];
  HC: IProgramType[];
};
const SelectProgramComponent: React.FC<Props> = observer(({ autoSave = false, noManagerRequired = false }) => {
  // Import Styles
  const classes = useProgramBoxStyle();
  const navigate = useNavigate();

  // Import Context
  const {
    currentItem,
    updateCurrentItemDetails,
    stationType,
    linkedItem,
    hasSpecialAttentionItems,
    bag,
    specialItemsInBag,
    specialAttentionItemsList,
    linkSpecialAttentionItemToBag,
    unlinkSpecialAttentionItemFromBag,
    unlinkItemToSpecialAttentionItem,
    linkItemToSpecialAttentionItem,
  } = useStationStore();
  // Declare States
  const [currentListing, setCurrentListing] = useState<{}>();
  const [currentCategory, setCurrentCategory] = useState(
    getSelectedCategoryFromProgram(currentItem && currentItem.programs ? currentItem.programs.program : ({} as IProgramType))
  );
  const [preSelectedObj, setPreSelectedObj] = useState<PreSelectedProgramsType>({} as PreSelectedProgramsType);
  const [isOpen, setisOpen] = useState(false);
  const [toggleManagerFlag, setToggleManagerFlag] = useState(false);
  const [onHoldProgram, setOnHoldProgram] = useState<IProgramType>({} as IProgramType);
  const [linkedItemFlag, setLinkedItemFlag] = useState(false);

  useEffect(() => {
    if (linkedItem && linkedItem.cleaningInstruction) setLinkedItemFlag(true);
  }, [linkedItem]);

  useEffect(() => {
    //Fetch Suggested Programs
    const fetchPrograms = async () => {
      // Send the Suggestion Request
      const preSelectedPrograms = await stationService.fetchPreselectedPrograms(
        currentItem.type as string,
        currentItem.colorsList
          ? currentItem.colorsList.map((item) => {
              return item.name;
            })
          : []
      );
      // Parse the response to get the item's
      const parsedObj = parseSuggestedPrograms(preSelectedPrograms);
      // Save the programs Obj to the state
      setPreSelectedObj(parsedObj as PreSelectedProgramsType);
    };
    fetchPrograms();
  }, [currentItem.colorsList, currentItem.type]);

  // Update CurrentListing State
  useEffect(() => {
    setCurrentListing(programMapper[currentCategory] || {});
  }, [currentCategory]);

  /**
   * Set the selected catgory
   * @param label - Name of the Catogory selected
   */
  const onCategoryClick = (label: string) => {
    setCurrentCategory(label);
  };

  /**
   * Save the selected value to  the StationStore's current Item
   * @param program - string -> program name in IProgramType
   */

  const onProgramSelect = (programKey: string, category?: string) => {
    const ProgramCategory = category ? category : currentCategory;
    const programType: IProgramType = programMapper[ProgramCategory][programKey];

    const isProgramSuggested: boolean = isSuggested(preSelectedObj, ProgramCategory as programsCategories, programType.key);

    if (currentItem && currentItem.isRecurrent && !noManagerRequired) {
      // Save the program while waiting for manager confirmation
      setOnHoldProgram(programType);
      setToggleManagerFlag(true);
      return;
    }
    // Handle first time items
    updateCurrentItem(programType, isProgramSuggested);
  };

  const onToggleDialog = () => {
    setisOpen(!isOpen);
  };

  function gotToPhoto() {
    if (currentItem.frontImage) {
      return navigate('../');
    } else {
      return navigate(`../(item/photo)`);
    }
  }

  const rejectGarment = async () => {
    updateCurrentItemDetails({
      ...currentItem,

      // Temporary solution for Now
      programs: {
        program: {
          name: 'Wet Cleaning Lights',
          key: 'wet_cleaning_lights',
          digit: '15',
        },
      },
    });
    setisOpen(false);
    await stationService.rejectItem(currentItem.rfid);
    toast(<Toaster message="Item Rejected" type="success" />);
    gotToPhoto();
  };

  const updateCurrentItem = async (item?: IProgramType, isPreselected = false) => {
    // Form the Programs Obj
    // TEMP: To be removed once we will be able to send the IProgramType obj only
    const programsObj: Programs = {
      program: item || onHoldProgram,
      dryingProgram: {},
      subProgram: {},
    };

    // Check if the user is on the washer station
    const isWasherStation = stationType === 'washer';

    //update item details via api if autoSave is true
    if (autoSave) {
      const item = await StationService.updateItem(
        {
          ...currentItem,
          programs: programsObj,
        },
        '',
        isWasherStation
      );

      // Save the program and the bucket to the store
      updateCurrentItemDetails({ ...currentItem, bucket: item.bucket, programs: programsObj });
    } else {
      // Save the new program to the store
      updateCurrentItemDetails({ ...currentItem, programs: programsObj });
    }
    // Route to the next view - Photo Modal View
    if (currentItem.frontImage) {
      navigate(-1);
    } else {
      onGotToPhoto();
    }
  };

  /** Route to the Brands Modal View */
  function onGotToPhoto() {
    navigate(`../(item/photo)`);
  }

  /** Flag for showing the preSelection */
  const showPreselection = currentCategory && Boolean(preSelectedObj) ? false : true;

  return (
    <>
      <LoaderComponent />

      {!linkedItem?.isOrderInstruction && (
        <ItemBanner withItemInfo currentItem={currentItem} linkedItem={linkedItem}>
          <Button color="secondary" variant="outlined" size="large" className={classes.rejectBtn} onClick={onToggleDialog}>
            Reject Item
          </Button>
        </ItemBanner>
      )}

      <Box display="flex" flexDirection="column" className={classes.root} minHeight={0}>
        <Typography variant="h6" className={classes.labelTypo}>
          <FormattedMessage id={'station.sorter.itemProgram.categories.label'} defaultMessage={'station.sorter.itemType.categories.default'} />
        </Typography>
        <Box display="flex" paddingBottom="20px">
          {programCategories.map((label, i) => {
            return (
              <LabelSelector
                key={`${i}-${label}-type-mapper`}
                onToggleClick={() => onCategoryClick(label)}
                isActive={currentCategory === label}
                activeColor={categoryColorMapper[label]}
                width="25%"
                isFilled
                isRounded
              >{`station.sorter.itemProgram.categories.${label}`}</LabelSelector>
            );
          })}
        </Box>
        <Typography variant="h6" className={classes.labelTypo}>
          <FormattedMessage id={'station.sorter.itemProgram.programs.label'} defaultMessage={'station.sorter.itemType.categories.default'} />
        </Typography>
        <Box display="flex" flexWrap="wrap" alignItems="space-evenly" minHeight={0} className={classes.overflowBox}>
          {showPreselection ? (
            preSelectedObj && Object.keys(preSelectedObj).length === 0 ? (
              <Typography variant="h4">No Suggested Programs for this Type and Color</Typography>
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={3} md={3} lg={3} xl={3}>
                  <PreSelectedMapper
                    category="laundry"
                    notation="L"
                    preSelectedObj={preSelectedObj}
                    onToggleClick={onProgramSelect}
                    currentProgram={currentItem && currentItem.programs ? currentItem.programs.program.key : ''}
                  />
                </Grid>
                <Grid item xs={3} md={3} lg={3} xl={3}>
                  <PreSelectedMapper
                    category="wetCleaning"
                    notation="WC"
                    onToggleClick={onProgramSelect}
                    preSelectedObj={preSelectedObj}
                    currentProgram={currentItem && currentItem.programs ? currentItem.programs.program.key : ''}
                  />
                </Grid>
                <Grid item xs={3} md={3} lg={3} xl={3}>
                  <PreSelectedMapper
                    category="dryCleaning"
                    notation="DC"
                    onToggleClick={onProgramSelect}
                    preSelectedObj={preSelectedObj}
                    currentProgram={currentItem && currentItem.programs ? currentItem.programs.program.key : ''}
                  />
                </Grid>
                <Grid item xs={3} md={3} lg={3} xl={3}>
                  <PreSelectedMapper
                    category="handCleaning"
                    notation="HC"
                    onToggleClick={onProgramSelect}
                    preSelectedObj={preSelectedObj}
                    currentProgram={currentItem && currentItem.programs ? currentItem.programs.program.key : ''}
                  />
                </Grid>
              </Grid>
            )
          ) : (
            <Box width="100%">
              {currentListing &&
                Object.keys(currentListing).map((program: string, i) => {
                  return (
                    <LabelSelector
                      key={`${program}-${i}`}
                      width="23%"
                      onToggleClick={() => onProgramSelect(program)}
                      isRounded
                      isFilled
                      activeColor={categoryColorMapper[currentCategory]}
                      isActive={currentItem && currentItem.programs ? currentItem.programs.program.key === program : false}
                      isDashed={isSuggested(preSelectedObj, currentCategory as programsCategories, program)}
                    >
                      {`station.sorter.program.${program}`}
                    </LabelSelector>
                  );
                })}
            </Box>
          )}
        </Box>
        <ValidateManagerDialog
          open={toggleManagerFlag}
          onManagerScan={() => updateCurrentItem()}
          onClose={() => {
            setToggleManagerFlag(false);
          }}
        />
        <ConfirmDialog open={isOpen} onClose={onToggleDialog} image={alert} title={`Are you sure you want to reject Item?`}>
          <Box padding="1rem">
            <Button color="secondary" variant="contained" size="large" onClick={rejectGarment}>
              REJECT
            </Button>
          </Box>
          <Box padding="1rem">
            <Button variant="outlined" color="primary" size="large" onClick={onToggleDialog}>
              CANCEL
            </Button>
          </Box>
        </ConfirmDialog>
      </Box>
      <SaDialog
        isOpen={linkedItemFlag}
        type={SaDialogTypes.warning}
        onContinue={() => setLinkedItemFlag(false)}
        hideStainReport
        hasSpecialAttentionItems={hasSpecialAttentionItems}
        bag={bag}
        linkedItem={linkedItem}
        specialItemsInBag={specialItemsInBag}
        specialAttentionItemsList={specialAttentionItemsList}
        linkSpecialAttentionItemToBag={linkSpecialAttentionItemToBag}
        unlinkSpecialAttentionItemFromBag={unlinkSpecialAttentionItemFromBag}
        unlinkItemToSpecialAttentionItem={unlinkItemToSpecialAttentionItem}
        linkItemToSpecialAttentionItem={linkItemToSpecialAttentionItem}
      />
    </>
  );
});

export default SelectProgramComponent;
