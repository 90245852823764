import React, { useEffect, useState } from 'react';
import useHandleGetItemReportDetail from '../hooks/useHandleGetItemReportDetail';
import { Dialog } from '@material-ui/core';
import styled from 'styled-components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ReportsModal from '../components/modals/reportsModal';
import { observer } from 'mobx-react-lite';
import { useStationStore } from 'stores';
import { useQueryClient } from 'react-query';

import lodash from 'lodash';
import useUniversalItemNotesState from 'hooks/useUniversalItemNotesState';
import UniversalItemNoteModal from 'components/dialogs/UniversalItemNoteModal';
import InstructionsDialog from 'components/dialogs/instructionsDialog';
import useInstructionsDlg from 'hooks/useInstructionsDlg';

interface Props { }

interface RouteParams extends Record<string, string> {
  itemCode: string;
}

const StainedItemReport: React.FC<Props> = observer(() => {
  const { getOrderItemWithReports, clearData, stationId } = useStationStore();
  const queryClient = useQueryClient();
  const { itemCode } = useParams<RouteParams>();
  const state = useLocation().state as { orderAlphaId?: string } | 'stain' | 'damage';
  const navigate = useNavigate();

  const [orderAlphaId] = useState<string | undefined>(lodash.get(state, 'orderAlphaId', undefined));
  const [open, setOpen] = useState<boolean>(true);

  const { itemReportDetail, isError, refetch } = useHandleGetItemReportDetail(itemCode, orderAlphaId);

  const {
    noteForStation,
    open: openUniversalItemNotesModal,
    setOpen: setOpenUniversalItemNotesModal,
  } = useUniversalItemNotesState(stationId, itemReportDetail);

  const toggleItemReportsModal = () => {
    setOpen(false);
    queryClient.invalidateQueries(['ordersWithStainedItemsList'], { exact: false, active: true });
    navigate('../');

    openVerificationModal();
  };

  const openStainsDialog = async (type: 'stain' | 'damage') => {
    await getOrderItemWithReports(itemReportDetail.rfid ?? '');
    navigate(`stain-damage`, { state: type });
  };

  useEffect(() => {
    if (isError) {
      setOpen(false);
      navigate('../');
    }
  }, [isError, navigate]);

  //Reset on Unmount
  useEffect(
    () => () => clearData(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const {
    acknowledgementModalOpen,
    verificationModalOpen,
    verificationOrderInstructions,
    openAcknowledgementModal,
    openVerificationModal,
    closeAcknowledgementModal,
    nextVerificationInstruction,
    acknowledgementItemInstruction,
  } = useInstructionsDlg({
    entity: {
      type: 'item',
      itemCode: itemReportDetail.code,
      orderId: itemReportDetail.orderId,
    },
    stationId: stationId,
    onVerificationModalClose: () => {
      toggleItemReportsModal();
    },
  });

  useEffect(() => {
    openAcknowledgementModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemReportDetail.code, itemReportDetail.orderId]);

  const refetchData = async () => await refetch();
  return (
    <CustomDialog fullScreen open={open}>
      <ReportsModal
        handleRefetchItemReports={refetchData}
        openStainsDialog={openStainsDialog}
        itemReportDetail={itemReportDetail}
        toggle={openVerificationModal}
      />
      {noteForStation && (
        <UniversalItemNoteModal note={noteForStation} onClose={() => setOpenUniversalItemNotesModal(false)} open={openUniversalItemNotesModal} />
      )}

      <InstructionsDialog
        open={acknowledgementModalOpen}
        entity={{ type: 'item', itemCode: itemReportDetail.code, orderId: itemReportDetail.orderId }}
        stage="acknowledge"
        onClose={closeAcknowledgementModal}
        orderInstructions={acknowledgementItemInstruction || []}
      />
      <InstructionsDialog
        open={verificationModalOpen && !acknowledgementModalOpen}
        entity={{ type: 'item', itemCode: itemReportDetail.code, orderId: itemReportDetail.orderId }}
        stage="verify"
        onClose={nextVerificationInstruction}
        orderInstructions={verificationOrderInstructions}
      />
    </CustomDialog>
  );
});

const CustomDialog = styled(Dialog)`
  overflow: hidden;
`;

export default StainedItemReport;
