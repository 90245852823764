import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Grid, Button, CircularProgress, Theme, Typography } from '@material-ui/core';

import { SaDialogTypes } from 'utils/enums/stationEnums';
import { IBag, SaItem } from 'utils/interfaces';
import { linkActions } from './saDialog';

import { ReactComponent as LinkSVG } from 'assets/images/svgs/specialAttentionSVG/link.svg';
import { ReactComponent as UnlinkSVG } from 'assets/images/svgs/specialAttentionSVG/unlink.svg';
import { SAHandlerOptions } from 'modules/stations/finerySorterStation/hooks/useSaItems';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonMargin: {
      marginRight: '1rem',
    },
  })
);

type LinkToBagDisplayProps = Pick<Props, 'activeItem' | 'bag' | 'linkSpecialAttentionItemToBag' | 'onItemTag' | 'unlinkSpecialAttentionItemFromBag'>;

const LinkToBagDisplay: React.FC<LinkToBagDisplayProps> = ({
  activeItem,
  onItemTag = () => {},
  bag,
  linkSpecialAttentionItemToBag,
  unlinkSpecialAttentionItemFromBag,
}) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);

  const onAddToBag = async () => {
    setIsLoading(true);
    // Link Item to bag
    await linkSpecialAttentionItemToBag(activeItem.id);
    setIsLoading(false);

    // Move to the next Item
    onItemTag();
  };

  const onAddNotInBag = async () => {
    setIsLoading(true);
    // Unlink Item from bag
    await unlinkSpecialAttentionItemFromBag(activeItem.id);
    setIsLoading(false);

    // Move to the next Item
    onItemTag();
  };

  return (
    <Grid container justify="center" alignItems="center">
      {activeItem.bagCode && activeItem.bagCode !== bag.bagCode ? (
        activeItem.itemCode ? (
          <Typography variant="h5" style={{ fontSize: '2rem', margin: '1rem 0', color: '#3e48a6' }}>
            Item Linked in another bag
          </Typography>
        ) : (
          <Typography variant="h5" style={{ fontSize: '2rem', margin: '1rem 0', color: '#3e48a6' }}>
            Item in another bag
          </Typography>
        )
      ) : activeItem.itemCode ? (
        <Typography variant="h5" style={{ fontSize: '2rem', margin: '1rem 0', color: '#3e48a6' }}>
          Item Linked
        </Typography>
      ) : (
        !activeItem.isOrderInstruction && (
          <>
            <Button
              variant={activeItem.bagCode && !activeItem.notInBag ? 'contained' : 'outlined'}
              color="primary"
              size="large"
              onClick={onAddToBag}
              className={classes.buttonMargin}
              disableElevation
            >
              {isLoading ? (
                <CircularProgress color={activeItem.bagCode && !activeItem.notInBag ? 'inherit' : 'primary'} />
              ) : (
                <span style={{ color: activeItem.bagCode && !activeItem.notInBag ? '#fff' : '#3e48a6' }}>Item in Bag</span>
              )}
            </Button>
            <Button
              variant={!activeItem.bagCode && activeItem.notInBag ? 'contained' : 'outlined'}
              color="secondary"
              size="large"
              onClick={onAddNotInBag}
              disableElevation
            >
              {isLoading ? (
                <CircularProgress color={!activeItem.bagCode && activeItem.notInBag ? 'inherit' : 'secondary'} />
              ) : (
                <span style={{ color: !activeItem.bagCode && activeItem.notInBag ? '#fff' : '#ca3232' }}> Item Not In Bag </span>
              )}
            </Button>
          </>
        )
      )}
    </Grid>
  );
};

interface ViewOnlyDisplayProps {
  activeItem: SaItem;
  isSorter?: boolean;
}

const ViewOnlyDisplay: React.FC<ViewOnlyDisplayProps> = ({ activeItem, isSorter = false }) => {
  const { itemCode, bagCode, notInBag = false } = activeItem;
  const textColor = (bagCode && itemCode) || (bagCode && isSorter) ? '#3e48a6' : '#cd3d3d';
  return (
    <>
      <Typography variant="h5" style={{ fontSize: '2rem', margin: '1rem 0', color: textColor }}>
        {activeItem && bagCode && itemCode
          ? isSorter
            ? 'Item Linked'
            : 'Item Sorted'
          : isSorter
          ? bagCode
            ? 'Item In Bag'
            : notInBag
            ? 'Item Not In Bag'
            : 'Item Undecided'
          : 'Item Not Sorted'}
      </Typography>
    </>
  );
};

interface LinkToItemProps
  extends Pick<Props, 'linkedItem' | 'activeItem' | 'onItemLink' | 'unlinkItemToSpecialAttentionItem' | 'linkItemToSpecialAttentionItem'> {
  unlinkMode?: boolean;
}

const LinkToItemDisplay: React.FC<LinkToItemProps> = ({
  activeItem,
  unlinkMode = false,
  linkedItem,
  onItemLink = () => {},
  linkItemToSpecialAttentionItem,
  unlinkItemToSpecialAttentionItem,
}) => {
  const [linkFlag, setLinkFlag] = useState(false);

  const onLinkItem = async () => {
    if (unlinkMode) {
      await unlinkItemToSpecialAttentionItem();
      onItemLink(linkActions.unlinkSuccessful);
      return;
    }
    await linkItemToSpecialAttentionItem(activeItem.id);

    if (activeItem && activeItem.stainsArray && activeItem.stainsArray.length > 0) return onItemLink(linkActions.redirectToStain);

    onItemLink(linkActions.linkSuccessful);
  };

  return (
    <Grid container justify="center" alignItems="center">
      <Button
        variant="outlined"
        size="large"
        color={linkedItem ? 'secondary' : 'primary'}
        startIcon={linkedItem ? <UnlinkSVG style={{ width: '22px', height: '22px' }} /> : <LinkSVG style={{ width: '22px', height: '22px' }} />}
        onClick={() => setLinkFlag(true)}
        style={{ marginRight: '1rem' }}
      >
        {linkedItem ? 'Unlink Item' : 'Link to Item'}
      </Button>
      <Button variant={'contained'} color="primary" size="large" disabled={!linkFlag} onClick={onLinkItem} disableElevation>
        confirm
      </Button>
    </Grid>
  );
};

interface ItemWarningDisplayProps {
  onContinue?: () => void;
}

const ItemWarningDisplay: React.FC<ItemWarningDisplayProps> = ({ onContinue = () => {} }) => {
  return (
    <Grid container justify="center" alignItems="center">
      <Button variant={'contained'} color="primary" size="large" onClick={onContinue} disableElevation>
        Continue
      </Button>
    </Grid>
  );
};
interface Props
  extends Omit<SAHandlerOptions, 'specialItemsInBag' | 'hasSpecialAttentionItems' | 'isAllSaItemsLinked' | 'specialAttentionItemsList' | 'cleanUp'> {
  type: SaDialogTypes;
  activeItem: SaItem;
  bag: IBag;
  onItemLink?: (action: linkActions) => void;
  onItemTag?: () => void;
  onContinue?: () => void;
}

const SaDialogFooter: React.FC<Props> = ({ type, ...props }) => {
  switch (type) {
    case SaDialogTypes.linkToBag: {
      return <LinkToBagDisplay {...props} />;
    }

    case SaDialogTypes.viewOnly: {
      return <ViewOnlyDisplay {...props} />;
    }

    case SaDialogTypes.linkToItem: {
      return <LinkToItemDisplay {...props} />;
    }

    case SaDialogTypes.unlinkItem: {
      return <LinkToItemDisplay {...props} unlinkMode />;
    }

    case SaDialogTypes.warning: {
      return <ItemWarningDisplay {...props} />;
    }

    case SaDialogTypes.sorterViewOnly: {
      return <ViewOnlyDisplay {...props} isSorter />;
    }

    default:
      return <></>;
  }
};

export default SaDialogFooter;
