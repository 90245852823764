import { EntityDetails } from 'components/dialogs/instructionsDialog';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { StationService } from 'services';
import { ListInstructionsOptions, OrderInstruction } from 'utils/interfaces';

const useAcknowledgeOrderInstruction = () => {
  return useMutation<string, Error, { orderId: string; bagCode: string; instructionId: string }>(({ bagCode, instructionId, orderId }) =>
    StationService.acknowledgeOrderInstruction(orderId, bagCode, instructionId)
  );
};

const useAcknowledgeItemInstruction = () => {
  return useMutation<string, Error, { orderId: string; itemCode: string; instructionId: string }>(({ itemCode, orderId, instructionId }) =>
    StationService.acknowledgeItemInstruction(itemCode, orderId, instructionId)
  );
};

const useAcknowledgeItemInstructionsBatch = () => {
  return useMutation<string, Error, { instructionIds: string[] }>(({ instructionIds }) =>
    StationService.acknowledgeItemInstructionsBatch(instructionIds)
  );
};

const useVerifyOrderInstruction = () => {
  return useMutation<string, Error, { orderId: string; bagCode: string; instructionId: string; reason: string; isFollowed: boolean }>(
    ({ orderId, bagCode, instructionId, reason, isFollowed }) =>
      StationService.verifyOrderInstruction(orderId, bagCode, instructionId, reason, isFollowed)
  );
};

const useVerifyItemInstruction = () => {
  return useMutation<string, Error, { orderId: string; itemCode: string; instructionId: string; reason: string; isFollowed: boolean }>(
    ({ itemCode, orderId, instructionId, reason, isFollowed }) =>
      StationService.verifyItemInstruction(itemCode, orderId, instructionId, reason, isFollowed)
  );
};

const useVerifyItemInstructionsBatch = () => {
  return useMutation<string, Error, { instructionIds: string[]; reason: string; isFollowed: boolean }>(({ instructionIds, reason, isFollowed }) =>
    StationService.verifyItemInstructionsBatch(instructionIds, reason, isFollowed)
  );
};

const useViewOrderInstruction = () => {
  return useMutation<string, Error, { orderId: string; bagCode: string; instructionId: string }>(({ orderId, bagCode, instructionId }) =>
    StationService.viewOrderInstruction(orderId, bagCode, instructionId)
  );
};

const useViewItemInstruction = () => {
  return useMutation<string, Error, { orderId: string; itemCode: string; instructionId: string }>(({ itemCode, orderId, instructionId }) =>
    StationService.viewItemInstruction(itemCode, orderId, instructionId)
  );
};

const useViewInstructionsBatch = () => {
  return useMutation<string, Error, { instructionIds: string[] }>(({ instructionIds }) => StationService.viewItemInstructionsBatch(instructionIds));
};

const useListOrderInstructions = (orderId: string, options?: ListInstructionsOptions) => {
  return useQuery<{ orderInstructions: OrderInstruction[] }, Error, OrderInstruction[]>(
    ['orderInstructions', orderId, options],
    () => StationService.listOrderInstructions(orderId, options),
    {
      enabled: !!orderId,
      select: (data) => data?.orderInstructions.sort((a, b) => a.baseInstructionPriority - b.baseInstructionPriority),
    }
  );
};

const useMutationListOrderInstructions = () => {
  return useMutation<{ orderInstructions: OrderInstruction[] }, Error, { orderId: string; options?: ListInstructionsOptions }>(
    ({ orderId, options }) => StationService.listOrderInstructions(orderId, options)
  );
};

const LIST_INSTRUCTIONS_QUERY_KEY = 'itemInstructions';
const useListItemInstructions = (entity: EntityDetails | null, options?: ListInstructionsOptions) => {
  return useQuery<{ itemInstructions: OrderInstruction[] }, Error, OrderInstruction[]>(
    [LIST_INSTRUCTIONS_QUERY_KEY, entity, options],
    () => {
      switch (entity?.type) {
        case 'item':
          return StationService.listItemInstructions(entity.orderId, entity.itemCode, options);
        case 'package':
          return StationService.listItemInstructionsByPackageCode(entity.code, options);
        case 'dropoffBag':
          return StationService.listItemInstructionsByDropOffBag(entity.code, options);
        default:
          return { itemInstructions: [] };
      }
    },
    {
      enabled:
        !!entity &&
        ((entity.type === 'item' && !!entity.orderId && !!entity.itemCode) ||
          (entity.type === 'package' && !!entity.code) ||
          (entity.type === 'dropoffBag' && !!entity.code)),
      select: (data) => data?.itemInstructions?.sort((a, b) => a.baseInstructionPriority - b.baseInstructionPriority),
    }
  );
};

const useSetInstructionsByType = (orderId = '') => {
  const { data: orderInstructions } = useListOrderInstructions(orderId, {
    appliedTo: { type: true },
  });
  const queryClient = useQueryClient();

  const handleSetInstructionsByType = (type: string, itemCode: string) => {
    const orderInstructionsFound = orderInstructions?.filter((instruction) => instruction.itemDetails?.itemTypes?.includes(type || ''));
    const promises = orderInstructionsFound?.map((instruction) => StationService.createtItemInstructions(itemCode, orderId, instruction.id || ''));

    Promise.all(promises || []).then(() => {
      const queryKey: [string, EntityDetails] = [LIST_INSTRUCTIONS_QUERY_KEY, { type: 'item', orderId, itemCode }];
      queryClient.invalidateQueries(queryKey, { active: true, exact: false });
    });
  };

  return { handleSetInstructionsByType };
};
export {
  useAcknowledgeOrderInstruction,
  useAcknowledgeItemInstruction,
  useVerifyOrderInstruction,
  useVerifyItemInstruction,
  useViewOrderInstruction,
  useViewItemInstruction,
  useListOrderInstructions,
  useListItemInstructions,
  useSetInstructionsByType,
  useMutationListOrderInstructions,
  useViewInstructionsBatch,
  useAcknowledgeItemInstructionsBatch,
  useVerifyItemInstructionsBatch,
};
