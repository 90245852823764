import { useEffect, useMemo, useState } from 'react';
import { ListInstructionsOptions } from 'utils/interfaces';
import { EntityDetails } from 'components/dialogs/instructionsDialog';
import { useListItemInstructions } from 'services/mutations/useInstructions';
import { InstructionActionTypes } from 'utils/enums/stationEnums';

const useInstructionsDlg = ({
  stationId,
  onVerificationModalClose,
  instructionsOptions,
  entity,
}: {
  entity: EntityDetails | null;
  stationId?: string | undefined;
  onVerificationModalClose?: () => void;
  instructionsOptions?: Pick<ListInstructionsOptions, 'appliedTo'>;
}) => {
  const {
    data: acknowledgementItemInstruction,
    isSuccess: acknowledgementFetchedSuccess,
    refetch: refetchAcknowledgementItemInstruction,
  } = useListItemInstructions(entity, {
    ...instructionsOptions,
    stationId,
    actionType: InstructionActionTypes.ACKNOWLEDGEMENT,
  });

  const {
    data: verificationItemInstruction,
    isSuccess: verificationFetchedSuccess,
    isError: verificationHasError,
    refetch: refetchVerificationItemInstruction,
  } = useListItemInstructions(entity, {
    ...instructionsOptions,
    stationId,
    actionType: InstructionActionTypes.VERIFICATION,
  });

  const [acknowledgementModalOpen, setAcknowledgementModalOpen] = useState(false);
  const [verificationModalOpen, setVerificationModalOpen] = useState(false);
  const [activeVerificationInstructionIdx, setActiveVerificationInstructionIdx] = useState(-1);

  useEffect(() => {
    if (verificationModalOpen && ((verificationFetchedSuccess && verificationItemInstruction.length === 0) || verificationHasError)) {
      setVerificationModalOpen(false);
      setActiveVerificationInstructionIdx(-1);
      if (onVerificationModalClose) onVerificationModalClose();
    }
  }, [onVerificationModalClose, verificationFetchedSuccess, verificationHasError, verificationItemInstruction?.length, verificationModalOpen]);

  const openAcknowledgementModal = () => {
    setAcknowledgementModalOpen(true);
  };

  const closeAcknowledgementModal = () => {
    setAcknowledgementModalOpen(false);
  };

  const openVerificationModal = () => {
    setVerificationModalOpen(true);
    setActiveVerificationInstructionIdx(0);
  };

  const nextVerificationInstruction = () => {
    if (activeVerificationInstructionIdx === (verificationItemInstruction?.length || 0) - 1) {
      setVerificationModalOpen(false);
      setActiveVerificationInstructionIdx(-1);

      if (onVerificationModalClose) onVerificationModalClose();
      return;
    }

    setActiveVerificationInstructionIdx((prev) => prev + 1);
  };

  const verificationOrderInstructions = useMemo(
    () => (verificationItemInstruction?.[activeVerificationInstructionIdx] ? [verificationItemInstruction?.[activeVerificationInstructionIdx]] : []),
    [activeVerificationInstructionIdx, verificationItemInstruction]
  );
  return {
    acknowledgementItemInstruction,
    acknowledgementModalOpen: acknowledgementFetchedSuccess && acknowledgementItemInstruction?.length > 0 && acknowledgementModalOpen,
    setAcknowledgementModalOpen,
    verificationModalOpen: verificationFetchedSuccess && verificationItemInstruction?.length > 0 && verificationModalOpen,
    setVerificationModalOpen,
    verificationOrderInstructions,
    openAcknowledgementModal,
    openVerificationModal,
    closeAcknowledgementModal,
    nextVerificationInstruction,
    refetchVerificationItemInstruction,
    refetchAcknowledgementItemInstruction,
  };
};

export default useInstructionsDlg;
