import React from 'react';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { Toaster } from 'components/toaster';
import { AuthStore, uiState } from 'stores';
import { infoCodes } from 'utils/maps';

const ignoreLogoutErrorMessages = ['controllers.auth.validateManagerId.exits.unauthorized', 'userUnauthorizedForAction', 'controllers.auth.login.exits.stationAlreadyUsed'];
const ignoreInfoCodes = [infoCodes.PACKAGE_BELONGS_TO_DIFFERENT_DRIVER, infoCodes.STATION_ALREADY_USED];

export async function errorHandler(error: AxiosError) {
  const { response, message, code } = error;
  uiState.setIsLoading(false);
  if (response) {
    const {
      status,
      data: { infoCodes, message },
    } = response;
    if (status) {
      switch (status) {
        case 401:
        case 403: {
          // access forbidden - bad auth token => log out user
          const infoCode = (infoCodes ? Object.keys(infoCodes)[0] : '') as infoCodes;
          if (!ignoreLogoutErrorMessages.includes(response.data.message) && !ignoreInfoCodes.includes(infoCode)) {
            await AuthStore.logout(false);
          }
          break;
        }
        default: {
          // default action if status code was not found
        }
      }
      // for every info Code, we show a toast
      if (Boolean(infoCodes)) {
        Object.keys(infoCodes).map((code) => {
          const errorMessage = infoCodes[code].message || infoCodes[code].error || '';
          errorMessage && toast(<Toaster message={errorMessage} type="error" />);
          return code;
        });
      }

      // we check for a message property as well
      if (Boolean(message)) {
        toast(<Toaster message={message} type="error" />);
      }
    }
  }

  /**
   * @description handle time out and no internet errors here
   */
  switch (code) {
    case 'ECONNABORTED': {
      toast(<Toaster message="Connection timeout" type="error" />);
    }
  }

  switch (message) {
    case 'Network Error': {
      toast(<Toaster message="No Internet Connection" type="error" />);
    }
  }
  return Promise.reject(error);
}
