import { getStationId } from 'utils/helpers/station-store-local';
import { FilterDefaultValue, FilterOptions } from '../../../../../components/filters';
import { IssuesActionStatus } from '../../enums/stainManEnums';
import lodash from 'lodash';
import moment from 'moment';

const itemTypes = ['linens', 'shirts', 'others'];
const getItemTypesFromStationId = () => {
  const stationId = getStationId();
  let defaultType = undefined;
  itemTypes.forEach((value) => {
    if (stationId && stationId.includes(value)) {
      defaultType = value.toUpperCase();
    }
  });
  return defaultType ? defaultType : 'OTHERS';
};

const laundryCommonFilters: FilterOptions[] = [
  {
    filterKey: 'clusterIdsListFilter',
    title: 'Cluster',
    type: 'autoComplete',
    isMultipleSelection: true,
    filterOptions: [],
    defaultValue: [],
    xsValue: 3,
  },
  {
    filterKey: 'cleaningProgramsListFilter',
    title: 'Cleaning Program',
    type: 'autoComplete',
    isMultipleSelection: true,
    filterOptions: [],
    defaultValue: [],
    xsValue: 3,
  },
  {
    filterKey: 'actionStatusListFilter',
    title: 'Actioned',
    type: 'select',
    isMultipleSelection: true,
    defaultValue: [`${IssuesActionStatus.ACTION_ATTEMPTED}`, `${IssuesActionStatus.NOT_ACTIONED}`],
    filterOptions: [
      { label: 'No', value: `${IssuesActionStatus.NOT_ACTIONED}` },
      { label: 'Partially', value: `${IssuesActionStatus.ACTION_ATTEMPTED}` },
      { label: 'Yes', value: `${IssuesActionStatus.ACTIONED}` },
    ],
    xsValue: 2,
  },
  {
    filterKey: 'itemTypesCategoryFilter',
    title: 'Item Type Category',
    type: 'select',
    isMultipleSelection: true,
    filterOptions: itemTypes.map((value) => {
      return { label: lodash.startCase(value), value: value.toUpperCase() };
    }),
    defaultValue: [getItemTypesFromStationId()],
    xsValue: 2,
  },

  {
    filterKey: 'isStainedFilter',
    title: 'Item Reports',
    type: 'select',
    filterOptions: [
      { label: 'All', value: '' },
      { label: 'Stained', value: 'true' },
      { label: 'Damaged', value: 'false' },
    ],
    defaultValue: 'true',
    xsValue: 2,
  },
  {
    filterKey: 'dateFilter',
    title: 'Dropoff Date',
    type: 'date',
    multiple: false,
    defaultValue: { start: new Date(), end: moment().add('2', 'days').toDate() },
    xsValue: 3,
  },
];
const laundryDefaultCommonValues = (() => {
  const obj: { [key in string]?: FilterDefaultValue } = {};
  laundryCommonFilters?.forEach((filter) => {
    obj[filter.filterKey] = filter.defaultValue;
  });
  return obj;
})();

export { laundryCommonFilters, laundryDefaultCommonValues };
