import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { Grid, Button, Theme, Typography, Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';

import { useStationStore } from 'stores';
import { GreenButton } from 'components/buttons';
import { Toaster } from 'components/toaster';
import { ConfirmDialog } from 'components/confirmDialog';
import { LoaderComponent } from 'components/loaderComponent';
import { ImagePopup } from 'components/imagePopup';
import stationService from 'services/station.service';
import { TypeValueMapper } from 'utils/maps/typeValueMapper';
import { BagTypes, StationTypes } from 'utils/enums/stationEnums';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import alert from 'assets/images/pngs/alert.png';
import useVerifyStation from 'modules/stations/sorterStation/hooks/useVerifyStation';
import { ValidateManagerDialog } from 'components/dialogs';
import { useCanInformAndProceedDelicateItemsOnWasher } from 'modules/stations/sorterStation/hooks/useVerifyUserPermissions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: '1',
      border: 'solid 2px #b6bcd1',
      margin: theme.spacing(3),
      width: 'auto',
      borderRadius: '8px',
      minHeight: 0,
      flexWrap: 'nowrap',
      position: 'relative',
    },
    tableHeader: {
      backgroundColor: '#e8e9f4',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
    btnsContainer: {
      padding: theme.spacing(3),
      borderTop: 'solid 2px #b6bcd1',
    },
    itemRow: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      borderBottom: 'solid 2px #b6bcd1',
    },
    itemsContainer: {
      overflow: 'auto',
      flexWrap: 'nowrap',
    },
    mainWrapper: {
      minHeight: 0,
      flexWrap: 'nowrap',
    },
    controlBtns: {
      minWidth: '50px',
      minHeight: '50px',
      borderRadius: '5rem',
      padding: 0,
      margin: '0 .5rem',
    },
  })
);

interface Props {
  autoSave?: boolean;
  handleOnFinish?: () => void;
}

const ReportOverView: React.FC<Props> = observer(({ autoSave = false, handleOnFinish = () => {} }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { currentItem, deleteReportFromItem, updateItemRequest, bag } = useStationStore();
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [isDelicate, setIsDelicate] = useState<boolean>();
  const [isStained, setIsStained] = useState(false);
  const [isDamaged, setIsDamaged] = useState(false);
  const [managerOpen, setManagerOpen] = useState(false);
  const isWasherStation = useVerifyStation(StationTypes.Washer);
  const { mutateAsync: canInformAndProceedDelicateItemsOnWasher } = useCanInformAndProceedDelicateItemsOnWasher();
  const isBtnDisabled = !(currentItem.reportDetailsList && currentItem.reportDetailsList.length > 0);
  const addStainDamage = () => {
    navigate('../');
  };

  const deleteReport = (index: number) => {
    //calling setIsStained to set a rerender on the useEffect
    setIsStained(false);
    deleteReportFromItem(index);
    toggleDialog();
  };

  const toggleDialogItemIndex = (index: number) => {
    setIndex(index);
    setOpen(!open);
  };
  const toggleDialog = () => {
    setOpen(!open);
  };

  const editReport = (index: number) => {
    navigate(`../editDetails/${index}`);
  };

  const rejectGarment = async () => {
    await updateItemRequest(currentItem);
    await stationService.rejectItem(currentItem.rfid);
    toast(<Toaster message="Item Rejected" type="success" />);
    handleOnFinish();
  };

  const sendToApproval = async () => {
    await updateItemRequest(currentItem);
    await stationService.sendItemToApproval(currentItem.rfid);
    toast(<Toaster message="Item Sent to Approval" type="success" />);
    handleOnFinish();
  };

  const onProceed = async () => {
    setManagerOpen(false);
    await stationService.informAndProceed(currentItem.rfid);

    if (autoSave) {
      await updateItemRequest(currentItem);
    }

    handleOnFinish();
  };

  const proceedWithManagerScan = async (rfidTag?: string) => {
    console.log('rfidTag', rfidTag);
    if (!rfidTag) {
      return;
    }
    await canInformAndProceedDelicateItemsOnWasher({ rfidTag }, { onSuccess: onProceed });
  };

  useEffect(() => {
    const { reportDetailsList, brand } = currentItem;
    if (reportDetailsList) {
      //Check if any item in the report List is Stained
      reportDetailsList.map((detailsList) => {
        if (detailsList.reason === 'STAINED') setIsStained(true);
        if (detailsList.reason === 'DAMAGED') setIsDamaged(true);
        return null;
      });
    }
    if (bag.type !== BagTypes.PRESS) {
      if (brand) {
        //If delicate and is stained, disable Inform and Proceed Btn for all bags except Press only
        if (brand.Delicate && (isStained || isDamaged)) {
          setIsDelicate(true);
        } else {
          setIsDelicate(false);
        }
      }
    }
  }, [setIsDelicate, currentItem, isStained, bag, isDamaged]);

  return (
    <Grid container direction="column" justify="space-between" classes={{ container: classes.root }}>
      <LoaderComponent />
      <Grid classes={{ container: classes.mainWrapper }} container item direction="column">
        <Grid container item xs direction="row" justify="space-between" classes={{ container: classes.tableHeader }}>
          <ConfirmDialog onClose={toggleDialog} image={alert} open={open} title={`Are you sure you want to delete this report ?`}>
            <Box padding="1rem">
              <Button onClick={toggleDialog} color="primary" variant="outlined" size="large">
                Cancel
              </Button>
            </Box>
            <Box padding="1rem">
              <Button variant="contained" color="primary" size="large" onClick={() => deleteReport(index)}>
                Confirm
              </Button>
            </Box>
          </ConfirmDialog>
          <Grid item xs>
            <Typography variant="h4">Image</Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="h4">Report Type</Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="h4">Type</Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="h4">Part</Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="h4">Location</Typography>
          </Grid>
          <Grid item xs></Grid>
        </Grid>
        <Grid classes={{ container: classes.itemsContainer }} container direction="column" alignItems="flex-start">
          {currentItem.reportDetailsList &&
            currentItem.reportDetailsList.map((report, i) => (
              <Grid container item xs direction="row" justify="space-between" key={i} classes={{ item: classes.itemRow }}>
                <Grid item xs>
                  <ImagePopup imageUrl={report.imageUrl} index={i} />
                </Grid>
                <Grid item xs>
                  <Typography variant="h4">{report.reason}</Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant="h4">{TypeValueMapper[report.type]}</Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant="h4">{report.part}</Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant="h4">{report.location}</Typography>
                </Grid>
                <Grid item xs>
                  <Button color="primary" variant="contained" classes={{ root: classes.controlBtns }} onClick={() => editReport(i)}>
                    <EditIcon />
                  </Button>
                  <Button color="secondary" variant="contained" classes={{ root: classes.controlBtns }} onClick={() => toggleDialogItemIndex(i)}>
                    <DeleteIcon />
                  </Button>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Grid>
      <Grid container item alignItems="center" justify="space-evenly" classes={{ container: classes.btnsContainer }}>
        <Button color="secondary" variant="outlined" size="large" onClick={addStainDamage}>
          Add Stain/Damage
        </Button>
        <Button color="secondary" variant="contained" size="large" onClick={rejectGarment} disabled={isBtnDisabled}>
          Reject Garment
        </Button>
        <Button color="primary" variant="contained" size="large" onClick={sendToApproval} disabled={isBtnDisabled}>
          Send to approval
        </Button>
        <GreenButton
          variant="contained"
          size="large"
          onClick={() => (isDelicate && isWasherStation ? setManagerOpen(true) : onProceed())}
          disabled={isDelicate && !isWasherStation}
        >
          Inform and proceed
        </GreenButton>
      </Grid>
      <ValidateManagerDialog
        onManagerScan={(_, managerId) => proceedWithManagerScan(managerId)}
        open={managerOpen}
        onClose={() => setManagerOpen(false)}
        autoValidate={false}
      />
    </Grid>
  );
});

export default ReportOverView;
