import React, { useState, FormEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { Grid, FormControl, InputLabel, Select, MenuItem, Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useStationStore } from 'stores';
import LabelSelector from '../../labelSelector/LabelSelector';
import { getSelectedCategoryFromType } from '../selectTypeDialog';
import { ItemBanner } from 'modules/stations/components/itemBanner';
import { ItemTypes } from 'utils/enums/typesEnum';
import { DetailsList, ReportType } from 'utils/interfaces';
import { TypeValueMapper } from 'utils/maps/typeValueMapper';
import { ItemTypes as ItemTypesType } from 'utils/interfaces/stationInterfaces';

interface StateProps {
  type: ReportType;
  image: string;
}

export const reportDamagedTypes: { [key in 'STAINED' | 'DAMAGED']: string[] } = {
  DAMAGED: [
    'CHAIN_DAMAGE',
    'COLOR_BLEEDING',
    'FADING_COLOR',
    'IRON_MARK',
    'LINT',
    'LOOSE_THREADS',
    'MISSING_BEADS',
    'MISSING_BUTTON',
    'PLEATS_REMOVED',
    'PRINT_DAMAGE',
    'PRINT_FADING',
    'RIP',
    'SMALL_HOLES',
    'ZIPPER_DAMAGE',
    'BROKEN_BUTTONS',
    'OTHER',
  ],
  STAINED: ['HARD_STAIN', 'OIL_STAIN', 'RUST_STAIN', 'SWEAT_STAIN', 'FOOD_STAIN', 'DIRT_STAIN', 'OTHER'],
};

export const partsOtherMapperArray = ['BODY', 'COLLAR', 'LINING', 'LAPEL', 'SLEEVE'];

export const partsCategoryMapper: { [key: string]: string[] } = {
  top: ['SLEEVE', 'COLLAR', 'BODY'],
  bottom: ['WAIST', 'LEG'],
  linen: [],
};

export const partsTypeMapper: { [key: string]: string[] } = {
  SHIRT: ['CUFF'],
  SKIRT: ['BODY'],
};

export const partsMapper = (itemType?: ItemTypesType) => {
  if (!itemType) {
    return [];
  }
  const category = getSelectedCategoryFromType(itemType);
  return [...(partsCategoryMapper[category] || partsOtherMapperArray), ...(partsTypeMapper[itemType] || [])];
};

export const reportLocation: string[] = ['FRONT', 'BACK'];

interface Props {
  onSubmit: (formDetails: DetailsList, index?: string) => void;
}

const useStyles = makeStyles({
  root: {
    flex: '1',
  },
  imageContainer: {
    padding: '1rem',
    border: '1px solid #b6bcd1',
    borderRadius: '0.3rem',
  },
  formControl: {
    display: 'flex',
    marginBottom: '2rem',
  },
});

export const reasonMapper: { [key: string]: 'DAMAGED' | 'STAINED' } = {
  damage: 'DAMAGED',
  stain: 'STAINED',
};

// TODO: handle if index not found and other cases

const AddStainDamageDetails: React.FC<Props> = observer(({ onSubmit }) => {
  const classes = useStyles();
  const locationState = useLocation().state as StateProps | null;
  const { index } = useParams<{ index: string }>();
  const navigate = useNavigate();

  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const { currentItem, linkedItem } = useStationStore();
  const [reportForm, setReportForm] = useState<DetailsList>({
    imageUrl: locationState?.image || '',
    location: '',
    part: '',
    type: '',
    reason: reasonMapper[locationState?.type || ''] || '',
  });
  const [btnEnabled, setBtnEnabled] = useState(true);
  const [labelWidth, setLabelWidth] = React.useState(0);
  const [parts, setParts] = useState<string[]>([]);

  React.useEffect(() => {
    setParts(partsMapper(currentItem.type));
  }, [currentItem.type]);

  React.useEffect(() => {
    const enabled = Boolean(
      reportForm.imageUrl && reportForm.location && (reportForm.part || parts.length === 0) && reportForm.type && reportForm.reason
    );
    setBtnEnabled(enabled);
  }, [reportForm, parts.length]);

  React.useEffect(() => {
    setLabelWidth(inputLabel.current!.offsetWidth);
  }, []);

  React.useEffect(() => {
    if (index && currentItem.reportDetailsList) {
      const intIndex = parseInt(index);
      setReportForm(currentItem.reportDetailsList[intIndex]);
    }
  }, [index, currentItem.reportDetailsList]);

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setReportForm((oldValues) => ({
      ...oldValues,
      [event.target.name as string]: event.target.value,
    }));
  };

  // add stain report to the current item list then route to overview
  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSubmit(reportForm, index);
    navigate(`../overView`);
  };
  return (
    <Grid container classes={{ container: classes.root }} alignItems={linkedItem ? 'flex-start' : 'center'} justify="center">
      {/* ItemBanner - Special Attention Item */}
      {linkedItem && !linkedItem.isOrderInstruction && <ItemBanner linkedItem={linkedItem} currentItem={currentItem} />}

      <Grid item xs={6} style={{ margin: '1rem 0' }}>
        <Grid container direction="row" justify="center">
          <LabelSelector
            pathType="stroke"
            iconName={ItemTypes.damage}
            isActive={reportForm.reason === 'DAMAGED'}
            onToggleClick={() => setReportForm({ ...reportForm, reason: 'DAMAGED', type: '' })}
          />
          <Box margin="0 2rem" />
          <LabelSelector
            iconName={ItemTypes.stain}
            isActive={reportForm.reason === 'STAINED'}
            onToggleClick={() => setReportForm({ ...reportForm, reason: 'STAINED', type: '' })}
          />
        </Grid>
        <Grid container justify="center">
          <Grid classes={{ item: classes.imageContainer }} item>
            <img src={reportForm.imageUrl} alt="" className="img-responsive" style={{ width: '30rem' }} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <Grid container direction="row" alignItems="stretch">
          <Grid item xs={5}>
            <form onSubmit={handleFormSubmit}>
              <FormControl variant="outlined" classes={{ root: classes.formControl }} required>
                <InputLabel ref={inputLabel} htmlFor="type-selector">
                  Type
                </InputLabel>
                <Select
                  disabled={!reportForm.reason}
                  variant="outlined"
                  labelWidth={labelWidth}
                  value={reportForm.type}
                  onChange={handleChange}
                  inputProps={{
                    name: 'type',
                    id: 'type-selector',
                  }}
                >
                  {reportForm.reason &&
                    reportDamagedTypes[reportForm.reason].map((name) => (
                      <MenuItem key={name} value={name}>
                        {TypeValueMapper[name]}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" classes={{ root: classes.formControl }} required={parts.length !== 0}>
                <InputLabel ref={inputLabel} htmlFor="part-selector">
                  Part
                </InputLabel>
                <Select
                  variant="outlined"
                  labelWidth={labelWidth}
                  value={reportForm.part}
                  onChange={handleChange}
                  inputProps={{
                    name: 'part',
                    id: 'part-selector',
                  }}
                  disabled={parts.length === 0}
                >
                  {parts.map((part) => (
                    <MenuItem key={part} value={part}>
                      {part}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" classes={{ root: classes.formControl }} required>
                <InputLabel ref={inputLabel} htmlFor="location-selector">
                  Location
                </InputLabel>
                <Select
                  variant="outlined"
                  labelWidth={labelWidth}
                  value={reportForm.location}
                  onChange={handleChange}
                  inputProps={{
                    name: 'location',
                    id: 'location-selector',
                  }}
                >
                  {reportLocation.map((location) => (
                    <MenuItem key={location} value={location}>
                      {location}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button variant="contained" size="large" color="primary" type="submit" disabled={!btnEnabled}>
                Next
              </Button>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default AddStainDamageDetails;
