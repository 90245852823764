import Axious from './api';
import { AxiosRequestConfig } from 'axios';
class AuthenticationService {
  /**
   *
   * @param username The employee RFID
   * @param password The Manager RFID
   * @param stationId Station Id that the employee is trying to login to
   */
  public async login(username: string, password: string, stationId: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: '/auth/login',
      data: {
        id: username,
        password: password,
        stationNumber: stationId,
      },
    };

    try {
      const response = await Axious.request(requestOptions);
      if (response.status === 200) {
        const { authToken, user } = response.data;
        localStorage.setItem('authToken', authToken);
        localStorage.setItem('userRfid', username);
        localStorage.setItem('userId', user.id);
        localStorage.setItem('userName', user.name);
        localStorage.setItem('userPermissions', JSON.stringify(user.permissionsList || []));
        localStorage.setItem('role', user.role || '');
      }
      return response;
    } catch (error) {
      throw error;
    }
  }

  /**
   * @description logs the user out by clearing the local storage
   */
  public async logout(isLoggedIn: boolean = true) {
    // if token is expired, means user is already logged out
    if (isLoggedIn) {
      try {
        const requestOptions: AxiosRequestConfig = {
          method: 'POST',
          url: '/auth/logout',
        };
        await Axious.request(requestOptions);
      } catch (error: any) {
        console.log('Error logging out', error);
        throw error;
      }
    }
    localStorage.removeItem('authToken');
    localStorage.removeItem('userRfid');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
  }

  async validateManagerId(managerId: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/managers/${managerId}/validate`,
    };
    const response = await Axious.request(requestOptions);
    return response;
  }
}

const authService = new AuthenticationService();

// export as singleton
export default authService;
